import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { SettingsConfirmationComponent } from '../../components';
import { restartAccountPermissionForIndividualConsent } from '../../store/slices/consent/slice';

const ConsentJointAccountEnable = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const consentId = useParams().id;

	const goBack = useCallback(() => {
		dispatch(restartAccountPermissionForIndividualConsent());
		navigate(`/consents/${consentId}/details/`);
	}, [dispatch, navigate, consentId]);

	return (
		<SettingsConfirmationComponent
			goBack={goBack}
			confirmationHeaderLabel={t('label.what_you_should_now_enable')}
			headerLabel={t('label.changing_the_status_to_enable_joint')}
			contentLabel={t('label.data_sharing_can_be_disabled_all_account')}
			onClick={goBack}
		/>
	);
};

export default withTranslation()(ConsentJointAccountEnable);
