import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

const AccountsWithNoEnabledNominatedComponent = ({ t, accounts }) => {
	const [open, setOpen] = useState(false);
	const iconUserWrapperClassName = classnames('settings-page__icon-arrow-wrapper', open && 'settings-page__icon-arrow-wrapper--rotated');
	const accountsContentWrapperClassName = classnames('settings-page__content-wrapper', open && 'settings-page__content-wrapper--visible');

	return (
		<div className="settings-page__expandable-item-component">
			<div className="settings-page__secondary-user-wrapper">
				<div className="settings-page__secondary-user-inner-wrapper settings-page__no-enabled-nominated-wrapper">
					<div className="settings-page__settings-item">
						<label className="settings-page__user-full-name">{t('label.accounts_with_no_enabled')}</label>
					</div>
					<div className={iconUserWrapperClassName} onClick={() => setOpen(!open)}>
						<span className="icon-arrow-ios-downward-outline"></span>
					</div>
				</div>
				<div className={accountsContentWrapperClassName}>
					{accounts?.map((account, index) => {
						return (
							<div key={index} className="settings-page__account-no-enabled-wrapper">
								<span>{account.maskedNumber}</span>
								<span>{account.displayName}</span>
							</div>
						);
					})}
					<div className="settings-page__no-enabled-nominated-footer">
						<span className="icon-alert-circle"></span>
						<div>{t('label.to_add_a_nominated_representatived')}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(AccountsWithNoEnabledNominatedComponent);
