import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { ErrorPage } from './';

const NotFoundPage = ({ t }) => {
	useEffect(() => {
		document.title = 'Not found page';
	}, []);

	return <ErrorPage icon="icon-question-mark-circle" title={t('label.page_not_found')} description={t('label.sorry_the_page_you_are_looking')} />;
};

export default withTranslation()(NotFoundPage);
