import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchProfiles = createAsyncThunk('member/fetchProfiles', async (memberId, { rejectWithValue }) => {
	try {
		const response = await axios.get(`${memberId}/profiles`);
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});
