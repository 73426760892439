import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const sendError = createAsyncThunk('member/sendError', async ({ message, stack }, { rejectWithValue }) => {
	try {
		const response = await axios.post(`errors`, { message, stack });
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});
