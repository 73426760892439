import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SettingsConfirmationComponent } from '../../components';

const SecondaryUserStatusDisable = ({ t }) => {
	const navigate = useNavigate();
	const goBack = () => navigate('/settings/secondary-user-permission');

	return (
		<SettingsConfirmationComponent
			goBack={goBack}
			confirmationHeaderLabel={t('label.what_you_should_know_when_you_disable')}
			headerLabel={t('label.this_will_stop')}
			contentLabel={t('label.you_may_want_to_check_disable')}
			footerLabel={t('label.this_permission_change')}
			onClick={goBack}
		/>
	);
};

export default withTranslation()(SecondaryUserStatusDisable);
