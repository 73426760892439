import { createSelector } from '@reduxjs/toolkit';

const selectAccountData = (state) => state.account;

// fetchAccounts
export const selectAccountsLoading = createSelector(selectAccountData, (t) => t?.fetchAccountsStatus === 'loading');
export const selectAllAccounts = createSelector(selectAccountData, (t) => t?.allAccounts);
export const selectAvailableForSharing = createSelector(selectAccountData, (t) => t?.availableForSharing);
export const selectUnavailableForSharing = createSelector(selectAccountData, (t) => t?.unavailableForSharing);

// setGlobalPermissionForAnAccount
export const selectGlobalPermissionForAnAccountLoading = createSelector(
	selectAccountData,
	(t) => t?.setGlobalPermissionForAnAccountStatus === 'loading'
);
export const selectGlobalPermissionForAnAccountChanged = createSelector(selectAccountData, (t) => t?.globalPermissionForAnAccountChanged);
export const selectSharingPermission = createSelector(selectAccountData, (t) => t?.sharingPermission);
export const selectIsApproved = createSelector(selectAccountData, (t) => t?.isApproved);
