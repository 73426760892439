import { createSlice } from '@reduxjs/toolkit';
import { globalConstants } from '../../../constants';
import { getItem, removeItem, setItem } from '../../../helpers';
import { fetchProfiles } from './thunks';

export const initialState = {
	profiles: [],
	selectedProfile: getItem(globalConstants.SELECTED_PROFILE),
	skipProfileScreen: getItem(globalConstants.SKIP_PROFILE_SCREEN),
	fetchProfilesStatus: 'idle' // 'idle' | 'loading' | 'succeeded' | 'failed',
};

const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		resetProfiles: (state) => {
			state.profiles = [];
		},
		selectProfile: (state, action) => {
			if (action.payload) {
				state.selectedProfile = action.payload;
			} else {
				state.selectedProfile = action.payload;
				state.fetchProfilesStatus = 'loading';
			}
		}
	},
	extraReducers(builder) {
		builder
			// fetchProfiles - START
			.addCase(fetchProfiles.pending, (state) => {
				state.fetchProfilesStatus = 'loading';
				state.error = '';
				state.profiles = [];
				state.selectedProfile = null;
				state.skipProfileScreen = false;
			})
			.addCase(fetchProfiles.fulfilled, (state, action) => {
				state.fetchProfilesStatus = 'succeeded';
				const businessProfiles = action.payload.filter(
					(p) => p.profileType === globalConstants.BUSINESS || p.profileType === globalConstants.SOLE_TRADER
				);
				const personalProfiles = action.payload.filter((p) => p.profileType === globalConstants.PERSONAL);
				let selectedProfile;
				let skipProfileScreen;
				if (businessProfiles?.length === 0 && personalProfiles?.length === 1) {
					selectedProfile = personalProfiles[0];
					skipProfileScreen = true;
				} else if (businessProfiles?.length === 1 && personalProfiles?.length === 0) {
					selectedProfile = businessProfiles[0];
					skipProfileScreen = true;
				}
				state.profiles = action.payload;
				state.selectedProfile = selectedProfile;
				state.skipProfileScreen = skipProfileScreen;
			})
			.addCase(fetchProfiles.rejected, (state, action) => {
				state.fetchProfilesStatus = 'failed';
				state.error = action.payload.errorMessage;
			});
		// fetchProfiles - END
	}
});

export const { resetProfiles, selectProfile } = profileSlice.actions;
export default profileSlice.reducer;
