import React from 'react';
import { useNavigate } from 'react-router-dom';

const LinkItemComponent = ({ id, route, icon, label }) => {
	const navigate = useNavigate();

	return (
		<div className="link-item-component" id={id} onClick={() => navigate(route)}>
			<span className={icon}></span>
			<label>{label}</label>
		</div>
	);
};

export default LinkItemComponent;
