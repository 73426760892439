import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { SettingsConfirmationComponent } from '../../components';
import { restartAccountPermissionForIndividualConsent } from '../../store/slices/consent/slice';

const ConsentJointAccountDisable = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const consentId = useParams().id;

	const goBack = useCallback(() => {
		dispatch(restartAccountPermissionForIndividualConsent());
		navigate(`/consents/${consentId}/details/`);
	}, [dispatch, navigate, consentId]);

	return (
		<SettingsConfirmationComponent
			goBack={goBack}
			confirmationHeaderLabel={t('label.what_you_should_now_disable')}
			headerLabel={t('label.the_data_sharing_arrangement_disable_joint')}
			contentLabel={t('label.data_sharing_can_be_activated_all_account')}
			onClick={goBack}
		/>
	);
};

export default withTranslation()(ConsentJointAccountDisable);
