import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchNotifications = createAsyncThunk('member/fetchNotifications', async (memberId, { rejectWithValue }) => {
	try {
		const response = await axios.get(`${memberId}/notifications`);
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const markNotificationAsRead = createAsyncThunk('member/markNotificationAsRead', async ({ memberId, notificationId }, { rejectWithValue }) => {
	try {
		const response = await axios.put(`${memberId}/notifications/${notificationId}`);
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});
