import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { HeaderComponent, DescriptionComponent, FooterComponent, LoaderComponent, CancelPopUpComponent } from '../components';
import { Button } from '../basiqComponents';
import { Navigate } from 'react-router-dom';
import { selectMemberId } from '../store/slices/member/selectors';
import { selectProfilesLoading, selectProfiles, selectSelectedProfile } from '../store/slices/profile/selectors';
import { selectConsentsLoading } from '../store/slices/consent/selectors';
import { selectProfile } from '../store/slices/profile/slice';
import { fetchProfiles } from '../store/slices/profile/thunks';
import { resetConsents, setConsentsLoader } from '../store/slices/consent/slice';

const ProfilesPage = ({ t }) => {
	const dispatch = useDispatch();
	const profilesLoading = useSelector(selectProfilesLoading);
	const memberId = useSelector(selectMemberId);
	const profiles = useSelector(selectProfiles);
	const selectedProfile = useSelector(selectSelectedProfile);
	const consentsLoading = useSelector(selectConsentsLoading);
	const [cancelPopUpVisible, setCancelPopUpVisible] = useState();

	useEffect(() => {
		document.title = 'Profile selection page';
	}, []);

	useEffect(() => {
		if (memberId) {
			dispatch(fetchProfiles(memberId));
			dispatch(resetConsents());
		}
	}, [dispatch, memberId]);

	useEffect(() => {
		!consentsLoading && dispatch(setConsentsLoader());
	}, [dispatch, consentsLoading]);

	if (selectedProfile) {
		return <Navigate to="/consents" />;
	}
	return (
		<div className="main-page profiles-page">
			<HeaderComponent />
			<DescriptionComponent title={t('label.select_your_profile')} content={t('label.please_select_the_profile')} />
			<>
				{profilesLoading ? (
					<LoaderComponent />
				) : (
					<>
						{profiles?.length > 0 ? (
							<div className="profiles-page__profiles-wrapper">
								{profiles.map((profile, index) => (
									<div key={index} className="profiles-page__profile" onClick={() => dispatch(selectProfile(profile))}>
										{profile.profileName}
									</div>
								))}
							</div>
						) : (
							<div className="profiles-page__no-profiles">{t('label.no_profiles')}</div>
						)}
						<div className="content-wrapper__bottom">
							<Button
								id="profile-selection-cancel-button"
								type="button"
								text={t('label.cancel')}
								theme="secondary-dark"
								spanClassName="primary-text"
								onClick={() => setCancelPopUpVisible(true)}
							/>
						</div>
					</>
				)}
			</>
			<FooterComponent />
			<CancelPopUpComponent
				visible={cancelPopUpVisible}
				goBack={() => setCancelPopUpVisible(false)}
				popupContent="label.by_not_choosing_the_profile"
				backRoute="/"
			/>
		</div>
	);
};

export default withTranslation()(ProfilesPage);
