import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchRepresentatives = createAsyncThunk('member/fetchRepresentatives', async ({ memberId, profileId }, { rejectWithValue }) => {
	try {
		const response = await axios.get(`${memberId}/representatives?profileId=${profileId}`);
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const fetchNotificationsSettings = createAsyncThunk('member/fetchNotificationsSettings', async (memberId, { rejectWithValue }) => {
	try {
		const response = await axios.get(`${memberId}/notifications/preference`);
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const setNotificationsSettings = createAsyncThunk(
	'member/setNotificationsSettings',
	async ({ memberId, sendNotifications, frequency }, { rejectWithValue }) => {
		try {
			const response = await axios.post(`${memberId}/notifications/preference`, { sendNotifications, frequency });
			return response?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const fetchSecondaryUsers = createAsyncThunk('member/fetchSecondaryUsers', async (memberId, { rejectWithValue }) => {
	try {
		const response = await axios.get(`${memberId}/secondary-users`);
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const changeSecondaryUserAccountStatus = createAsyncThunk(
	'member/changeSecondaryUserAccountStatus',
	async ({ memberId, accountId, secondaryUserMemberId, status, secondaryUserInstructionId }, { rejectWithValue }) => {
		try {
			const response = await axios.post(`${memberId}/secondary-users`, {
				accountId,
				secondaryUserMemberId,
				status,
				secondaryUserInstructionId
			});
			return response?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const representativesAssignNomination = createAsyncThunk(
	'member/representativesAssignNomination',
	async ({ memberId, profileId, accountId, representativeId, status, businessNominationId }, { rejectWithValue }) => {
		try {
			const response = await axios.post(`${memberId}/representatives`, {
				profileId,
				accountId,
				representativeId,
				status,
				businessNominationId
			});
			return response?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
