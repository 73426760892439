import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import classnames from 'classnames';
import { Button } from '../basiqComponents';
import { selectConsents, selectStopSharingLoading, selectStopSharingSuccess, selectWithdrawalFileLoading } from '../store/slices/consent/selectors';
import { selectMemberId } from '../store/slices/member/selectors';
import { selectSelectedProfile } from '../store/slices/profile/selectors';
import { fetchConsents, getWithdrawalPdfFile, stopSharingConsent } from '../store/slices/consent/thunks';

const StopSharingPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const consents = useSelector(selectConsents);
	const memberId = useSelector(selectMemberId);
	const selectedProfile = useSelector(selectSelectedProfile);
	const withdrawalFileLoading = useSelector(selectWithdrawalFileLoading);
	const stopSharingLoading = useSelector(selectStopSharingLoading);
	const stopSharingSuccess = useSelector(selectStopSharingSuccess);
	const consentId = useParams().id;
	let consent = consents && consents.find((consent) => consent.consentId === consentId);

	const stopSharingFirstModalPopUpClassName = classnames(
		'stop-sharing-page__first-modal-pop-up',
		!stopSharingSuccess && 'stop-sharing-page__first-modal-pop-up--visible'
	);
	const stopSharingFirstModalPopUpWrapperClassName = classnames(
		'stop-sharing-page__first-modal-pop-up-wrapper',
		!stopSharingSuccess && 'stop-sharing-page__first-modal-pop-up-wrapper--visible'
	);
	const stopSharingPopUpClassName = classnames('stop-sharing-page__pop-up', stopSharingSuccess && 'stop-sharing-page__pop-up--visible');
	const stopSharingPopUpWrapperClassName = classnames(
		'stop-sharing-page__pop-up-wrapper',
		stopSharingSuccess && 'stop-sharing-page__pop-up-wrapper--visible'
	);

	useEffect(() => {
		document.title = 'Consents page - stop sharing';
	}, []);

	const handleCancel = () => navigate(`/consents/${consent.consentId}/details`);

	const handleSubmit = () => {
		dispatch(fetchConsents({ memberId, profileId: selectedProfile.profileId, profileType: selectedProfile?.profileType }));
		handleCancel();
	};

	return (
		<>
			<Modal
				ariaHideApp={false}
				className={stopSharingFirstModalPopUpClassName}
				isOpen={!stopSharingSuccess}
				onRequestClose={handleCancel}
				overlayClassName={stopSharingFirstModalPopUpWrapperClassName}
				shouldCloseOnOverlayClick={false}>
				<div className="stop-sharing-page__footer-question">
					{t('label.do_you_want_to_stop_sharing_data', { ADR: consent && consent.orgName })}
				</div>
				<div className="stop-sharing-page__footer-btns-container">
					<Button
						id="yes-stop-sharing-button"
						type="submit"
						text={t('label.yes_stop_sharing')}
						theme="secondary-dark"
						spanClassName="primary-text"
						loading={stopSharingLoading}
						onClick={() => consent && dispatch(stopSharingConsent({ memberId, consentId }))}
					/>
					<Button
						id="no-go-back-button"
						text={t('label.no_cancel')}
						theme="secondary-dark"
						spanClassName="primary-text"
						onClick={handleCancel}
					/>
				</div>
			</Modal>
			<Modal
				ariaHideApp={false}
				className={stopSharingPopUpClassName}
				isOpen={stopSharingSuccess}
				onRequestClose={handleCancel}
				overlayClassName={stopSharingPopUpWrapperClassName}
				shouldCloseOnOverlayClick={false}>
				<div>
					<div className="stop-sharing-page__pop-up-info">
						<span className="icon-checkmark-circle-2"></span>
						<div>{t('label.successfully_stopped_sharing')}</div>
					</div>
					<div className="stop-sharing-page__pop-up-title">
						{t('label.you_have_successfully_stopped_sharing_data_with', {
							ADR: consent && consent.orgName
						})}
					</div>
					{consent && (
						<span
							className="stop-sharing-page__pop-up-consent-withdrawal-confirmation"
							target="_blank"
							rel="noopener noreferrer"
							onClick={() =>
								!withdrawalFileLoading && dispatch(getWithdrawalPdfFile({ memberId, consentId, fileName: consent.withdrawal_pdf }))
							}>
							<span className="icon-file"></span>
							<div>{t('label.consent_withdrawal')}</div>
							{withdrawalFileLoading ? (
								<span itemProp="icon" className="icon-loader-outline rotating"></span>
							) : (
								<span itemProp="icon" className="icon-download-outline"></span>
							)}
						</span>
					)}
					<Button
						id="stop-sharing-okay-button"
						type="submit"
						text={t('label.okay')}
						theme="secondary-dark"
						spanClassName="primary-text"
						onClick={handleSubmit}
					/>
				</div>
			</Modal>
		</>
	);
};

export default withTranslation()(StopSharingPage);
