import React from 'react';

const ConfirmationHeaderComponent = ({ icon = 'icon-alert-circle', label, contentLabel }) => (
	<div className="confirmation-header-component">
		<span className={icon}></span>
		<div>
			<div className="confirmation-header-component__title">{label}</div>
			{contentLabel && <div className="confirmation-header-component__content-label">{contentLabel}</div>}
		</div>
	</div>
);

export default ConfirmationHeaderComponent;
