import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ConfirmationHeaderComponent, HeaderComponent } from '../../components';
import { restartGlobalPermissionForAnAccount } from '../../store/slices/account/slice';
import { selectGlobalPermissionForAnAccountChanged } from '../../store/slices/account/selectors';
import { selectNotifications } from '../../store/slices/notification/selectors';

const NotificationApprovePage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notifications = useSelector(selectNotifications);
	const globalPermissionForAnAccountChanged = useSelector(selectGlobalPermissionForAnAccountChanged);
	const notificationId = useParams().id;
	let notification = notifications && notifications.find((notification) => notification.notificationId === notificationId);
	const goBack = () => navigate('/notifications');

	useEffect(() => {
		globalPermissionForAnAccountChanged && dispatch(restartGlobalPermissionForAnAccount());
	}, [dispatch, globalPermissionForAnAccountChanged]);

	return (
		<>
			<HeaderComponent onBack={goBack} />
			<div className="notifications-page__details">
				<>
					<div className="notifications-page__body-wrapper">
						<ConfirmationHeaderComponent icon="icon-checkmark-outline" label={t('label.data_sharing_permission_has_been_changed')} />
						<div className="notifications-page__notification-information">
							<div>{t('label.joint_account_data_sharing_permission', { accountName: notification.accountName })}</div>
							<div>{t('label.a_confirmation_sms_to_data_sharing_permission')}</div>
						</div>
					</div>
				</>
			</div>
		</>
	);
};

export default withTranslation()(NotificationApprovePage);
