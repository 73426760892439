import { createSelector } from '@reduxjs/toolkit';
import { globalConstants } from '../../../constants';
import { sendError } from '../error/thunks';
import moment from 'moment-timezone';
import { selectSelectedProfile } from '../profile/selectors';

const selectConsentData = (state) => state.consent;

export const selectIsActiveSelected = createSelector(selectConsentData, (t) => t?.isActiveSelected);

// fetchConsents
export const selectConsentsLoading = createSelector(selectConsentData, (t) => t?.fetchConsentsStatus === 'loading');
export const selectConsents = createSelector([selectConsentData, selectSelectedProfile], (t, p) =>
	t?.consents?.map((consent) => {
		try {
			let newConsent = { ...consent };
			const { expiresAt, createdAt, updatedAt } = newConsent;
			let expiresAtFormated = moment(expiresAt * 1000).format('DD MMM YYYY');
			let createdAtFormated = moment(createdAt * 1000).format('DD MMM YYYY');
			let updatedAtFormated = moment(updatedAt * 1000).format('DD MMM YYYY');
			newConsent.whenYouGaveConsent = createdAtFormated;
			newConsent.expiresAtFormated = expiresAtFormated;
			newConsent.createdAtFormated = createdAtFormated;
			newConsent.updatedAtFormated = updatedAtFormated;
			// Active
			if (newConsent.status === 'active') {
				newConsent.sharingPeriodDate = `${createdAtFormated} - ${expiresAtFormated}`;
				newConsent.sharingConditionItemTitle = 'label.how_often_your_data_will_be_shared';
				newConsent.sharingConditionItemDate = 'label.your_data_will_be_shared_during_this_period';
				newConsent.statusText = 'label.active';
			} else {
				// Inactive
				newConsent.status = newConsent.inactiveStatusReason;
				newConsent.statusText = `label.${newConsent.inactiveStatusReason}`;
				// Withdrawn (Cancelled)
				if (newConsent.inactiveStatusReason === 'withdrawn') {
					newConsent.sharingPeriodDate = `${createdAtFormated} - ${updatedAtFormated}`;
					newConsent.sharingConditionItemTitle = 'label.when_you_cancelled_your_consent';
					newConsent.sharingConditionItemDate = updatedAtFormated;
					newConsent.statusText = 'label.cancelled';
				}
				// Expired
				else {
					newConsent.sharingPeriodDate = `${createdAtFormated} - ${expiresAtFormated}`;
					newConsent.sharingConditionItemTitle = 'label.when_your_consent_expired';
					newConsent.sharingConditionItemDate = expiresAtFormated;
				}
			}
			newConsent.accounts = newConsent.accounts?.map((account) => ({
				...account,
				accountType:
					!newConsent.isOwner && account.type === globalConstants.SECONDARY
						? globalConstants.SECONDARY
						: account.type === globalConstants.JOINT
						? globalConstants.JOINT
						: p.profileType === globalConstants.BUSINESS && globalConstants.BUSINESS,
				showToggle:
					newConsent.status === 'active' &&
					(account.type === globalConstants.JOINT ||
						p.profileType === globalConstants.BUSINESS ||
						(!newConsent.isOwner && account.type === globalConstants.SECONDARY)),
				isJointAccount: account.type === globalConstants.JOINT
			}));
			return newConsent;
		} catch (error) {
			sendError('consent - selectors - selectConsents - ', error);
			return consent;
		}
	})
);

export const selectMenuItems = createSelector(selectConsentData, (t) => t?.menuItems);

// getConfirmationPdfFile
export const selectConfirmationFileLoading = createSelector(selectConsentData, (t) => t?.getConfirmationPdfFileStatus === 'loading');

// getWithdrawalPdfFile
export const selectWithdrawalFileLoading = createSelector(selectConsentData, (t) => t?.getWithdrawalPdfFileStatus === 'loading');

// stopSharingConsent
export const selectStopSharingLoading = createSelector(selectConsentData, (t) => t?.stopSharingConsentStatus === 'loading');
export const selectStopSharingSuccess = createSelector(selectConsentData, (t) => t?.stopSharingSuccess);
