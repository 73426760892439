import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Routes, Route } from 'react-router-dom';
import { BottomNavigationBar, LoaderComponent, HeaderComponent } from '../../components';
import { globalConstants } from '../../constants';
import { isNotZero, useHookIsHBLBrand } from '../../helpers';
import {
	SettingsContentPage,
	SettingsNotificationPage,
	SecondaryUserPermissionPage,
	SecondaryUserStatusEnable,
	SecondaryUserStatusDisable,
	SettingsRepresentativePage,
	SettingsRepresentativeEnable,
	SettingsRepresentativeDisable
} from './';
import NotFoundPage from '../NotFoundPage';
import { selectMemberId } from '../../store/slices/member/selectors';
import { selectSelectedProfile } from '../../store/slices/profile/selectors';
import { selectNotifications, selectUnreadCount } from '../../store/slices/notification/selectors';
import { selectRepresentatives, selectRepresentativesLoading } from '../../store/slices/settings/selectors';
import { fetchNotifications } from '../../store/slices/notification/thunks';
import { fetchRepresentatives, fetchSecondaryUsers } from '../../store/slices/settings/thunks';
import { changeMenuItemsStatusNumber } from '../../store/slices/consent/slice';

const SettingsPage = ({ t }) => {
	const dispatch = useDispatch();
	const memberId = useSelector(selectMemberId);
	const selectedProfile = useSelector(selectSelectedProfile);
	const notifications = useSelector(selectNotifications);
	const unreadCount = useSelector(selectUnreadCount);
	const representativesLoading = useSelector(selectRepresentativesLoading);
	const representatives = useSelector(selectRepresentatives);
	const isHBLbrand = useHookIsHBLBrand();

	useEffect(() => {
		notifications?.length === 0 && dispatch(fetchNotifications(memberId));
		//eslint-disable-next-line
	}, [dispatch, memberId, t]);

	useEffect(() => {
		if (memberId && selectedProfile) {
			if (representatives?.length === 0 && selectedProfile.profileType === globalConstants.BUSINESS && !representativesLoading && !isHBLbrand) {
				dispatch(fetchRepresentatives({ memberId, profileId: selectedProfile.profileId }));
			} else if (selectedProfile.profileType === globalConstants.PERSONAL) {
				dispatch(fetchSecondaryUsers(memberId));
			}
		}
	}, [memberId, selectedProfile, dispatch]);

	useEffect(() => {
		isNotZero(unreadCount) && dispatch(changeMenuItemsStatusNumber({ tab: globalConstants.NOTIFICATIONS, statusNumber: unreadCount }));
	}, [dispatch, unreadCount]);

	return (
		<>
			<div className="main-page">
				{representativesLoading ? (
					<>
						<HeaderComponent />
						<div className="settings-page">
							<LoaderComponent />
						</div>
					</>
				) : (
					<Routes>
						<Route path="/" exact={true} element={<SettingsContentPage />} />
						<Route path="notification-settings" exact={true} element={<SettingsNotificationPage />} />
						<Route path="secondary-user-permission" exact={true} element={<SecondaryUserPermissionPage />} />
						<Route path="secondary-user-permission/enable" exact={true} element={<SecondaryUserStatusEnable />} />
						<Route path="secondary-user-permission/disable" exact={true} element={<SecondaryUserStatusDisable />} />
						<Route path="nominated-representative" exact={true} element={<SettingsRepresentativePage />} />
						<Route path="nominated-representative/enable" exact={true} element={<SettingsRepresentativeEnable />} />
						<Route path="nominated-representative/disable" exact={true} element={<SettingsRepresentativeDisable />} />
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				)}
			</div>
			<BottomNavigationBar />
		</>
	);
};

export default withTranslation()(SettingsPage);
