import { globalConstants } from '../constants';

export const setItem = (key, value) => sessionStorage.setItem(key, JSON.stringify(value));
export const getItem = (key) => JSON.parse(sessionStorage.getItem(key));
export const removeItem = (key) => sessionStorage.removeItem(key);

export const getAccessToken = async () => {
	try {
		const accessToken = getItem(globalConstants.CONSUMER_ACCESS_TOKEN);
		const tokenType = getItem(globalConstants.CONSUMER_TOKEN_TYPE);
		let response = {};
		if (accessToken) {
			const expirationDate = new Date(getItem(globalConstants.CONSUMER_EXPIRES_IN));
			if (expirationDate <= new Date()) {
				removeItem(globalConstants.CONSUMER_ACCESS_TOKEN);
				removeItem(globalConstants.CONSUMER_TOKEN_TYPE);
				removeItem(globalConstants.CONSUMER_EXPIRES_IN);
				response = {
					OK: false,
					message: 'Session expired'
				};
			} else {
				response = {
					OK: true,
					data: { accessToken, tokenType }
				};
			}
		} else {
			response = {
				OK: false
			};
		}
		return response;
	} catch (error) {
		return {
			OK: false
		};
	}
};

export const setAccessToken = (token, expiresIn, type) => {
	const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
	setItem(globalConstants.CONSUMER_ACCESS_TOKEN, token);
	setItem(globalConstants.CONSUMER_EXPIRES_IN, expirationDate);
	setItem(globalConstants.CONSUMER_TOKEN_TYPE, type);
};
