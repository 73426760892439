import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { downloadPdf } from '../../../helpers';

export const fetchConsents = createAsyncThunk('member/fetchConsents', async ({ memberId, profileId, profileType }, { rejectWithValue }) => {
	try {
		const response = await axios.get(`${memberId}/consents?profileId=${profileId}&profileType=${profileType}`);
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const setAccountPermissionForIndividualConsent = createAsyncThunk(
	'member/setAccountPermissionForIndividualConsent',
	async ({ consentId, memberId, accountId, sharingPermission }, { rejectWithValue }) => {
		try {
			const response = await axios.post(`${memberId}/consents/${consentId}/accounts/${accountId}`, { sharingPermission });
			return response?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);

export const getConfirmationPdfFile = createAsyncThunk('member/getConfirmationPdfFile', async ({ memberId, consentId }, { rejectWithValue }) => {
	try {
		const response = await axios.post(`${memberId}/consents/${consentId}/pdf`, { pdfType: 'confirmation' }, { responseType: 'blob' });
		downloadPdf(response.data, `${consentId} - confirmation.pdf`);
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const getWithdrawalPdfFile = createAsyncThunk('member/getWithdrawalPdfFile', async ({ memberId, consentId }, { rejectWithValue }) => {
	try {
		const response = await axios.post(`${memberId}/consents/${consentId}/pdf`, { pdfType: 'invalidation' }, { responseType: 'blob' });
		downloadPdf(response.data, `${consentId} - invalidation.pdf`);
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const getHistoryPdfFile = createAsyncThunk('member/getHistoryPdfFile', async ({ memberId, consentId, versionId }, { rejectWithValue }) => {
	try {
		const response = await axios.post(`${memberId}/consents/${consentId}/pdf`, { pdfType: 'confirmation', versionId }, { responseType: 'blob' });
		downloadPdf(response.data, `${consentId} - confirmation.pdf`);
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const stopSharingConsent = createAsyncThunk('member/stopSharingConsent', async ({ memberId, consentId }, { rejectWithValue }) => {
	try {
		const response = await axios.post(`${memberId}/consents/${consentId}/revoke`);
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});
