import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../basiqComponents';
import classnames from 'classnames';
import Modal from 'react-modal';

const CancelPopUpComponent = ({ t, goBack, visible, popupContent, backRoute, adr = '' }) => {
	const navigate = useNavigate();
	const cancelPopUpClassName = classnames('cancel-pop-up', visible && 'cancel-pop-up--visible');
	const cancelPopUpWrapperClassName = classnames('cancel-pop-up__wrapper', visible && 'cancel-pop-up__wrapper--visible');

	return (
		<Modal
			ariaHideApp={false}
			className={cancelPopUpClassName}
			isOpen={visible}
			onRequestClose={goBack}
			overlayClassName={cancelPopUpWrapperClassName}
			shouldCloseOnOverlayClick={false}>
			<div>
				<div className="cancel-pop-up__title">{t('label.are_you_sure_you_want_to_cancel')}</div>
				<div>
					<div className="cancel-pop-up__description">
						<span className="icon-question-mark-circle"></span>
						<div className="cancel-pop-up__description-wrapper">
							<div className="cancel-pop-up__description-header">{t('label.impacts_to_your_service')}</div>
							<div className="cancel-pop-up__description-content">
								{popupContent ? t(popupContent) : t('label.by_selecting_cancel_your_data', { ADR: adr })}
							</div>
						</div>
					</div>
				</div>
				<div className="cancel-pop-up__btns-container">
					<Button
						id="go-back-button"
						type="submit"
						text={t('label.no_go_back')}
						theme="secondary-dark"
						spanClassName="primary-text"
						onClick={goBack}
					/>
					<Button
						id="yes-cancel-button"
						type="submit"
						text={t('label.yes_cancel')}
						theme="secondary-dark"
						spanClassName="primary-text"
						onClick={() => navigate(backRoute || '/blank')}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default withTranslation()(CancelPopUpComponent);
