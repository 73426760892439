import React from 'react';

const RadioButtonComponent = ({ id, checked, onChange, reference, disabled, name, value }) => (
	<label id={id} className="container-checkbox">
		<input type="radio" name={name} value={value} checked={checked} onChange={onChange} ref={reference} disabled={disabled} />
		{checked ? <span className="icon-checkmark-square-2"></span> : <span className="icon-square"></span>}
	</label>
);

export default RadioButtonComponent;
