import { createSlice } from '@reduxjs/toolkit';
import { globalConstants } from '../../../constants';
import {
	fetchConsents,
	setAccountPermissionForIndividualConsent,
	getConfirmationPdfFile,
	getWithdrawalPdfFile,
	getHistoryPdfFile,
	stopSharingConsent
} from './thunks';

export const initialState = {
	isActiveSelected: true,
	consents: [],
	fetchConsentsStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	setAccountPermissionForIndividualConsentStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	getConfirmationPdfFileStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	getWithdrawalPdfFileStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	stopSharingSuccess: false,
	menuItems: [
		{
			tab: 'consents',
			iconName: 'consents',
			label: 'label.consents',
			route: '/consents',
			active: true
		},
		{
			tab: 'accounts',
			iconName: 'accounts',
			label: 'label.accounts',
			route: '/accounts'
		},
		{
			tab: 'notifications',
			iconName: 'notif',
			label: 'label.notifications',
			route: '/notifications'
		},
		{
			tab: 'settings',
			iconName: 'settings',
			label: 'label.settings',
			route: '/settings'
		}
	],
	bottomNavigationBarVisibility: true
};

const consentSlice = createSlice({
	name: 'consent',
	initialState,
	reducers: {
		setIsActiveSelected: (state, action) => {
			state.isActiveSelected = action.payload;
		},
		resetConsents: (state) => {
			state.consents = [];
		},
		setConsentsLoader: (state) => {
			state.fetchConsentsStatus = 'loading';
		},
		changeMenuItemsStatusNumber: (state, action) => {
			state.menuItems = state.menuItems.map((menuItem) => {
				if (menuItem.tab === action.payload.tab) {
					menuItem.statusNumber = action.payload.statusNumber;
				}
				return menuItem;
			});
		},
		changeMenuItems: (state, action) => {
			if (action.payload) {
				state.menuItems = state.menuItems.map((item) => {
					item.active = false;
					if (item.tab === action.payload.tab) {
						item.active = true;
					}
					return item;
				});
			}
		},
		restartAccountPermissionForIndividualConsent: (state) => {
			state.consents = state.consents.map((c) => {
				c.accounts?.map((acc) => {
					acc.loading = false;
					return acc;
				});
				return c;
			});
		}
	},
	extraReducers(builder) {
		builder
			// fetchConsents - START
			.addCase(fetchConsents.pending, (state) => {
				state.fetchConsentsStatus = 'loading';
				state.stopSharingSuccess = false;
			})
			.addCase(fetchConsents.fulfilled, (state, action) => {
				state.fetchConsentsStatus = 'succeeded';
				state.consents = action.payload?.sort((a, b) => b.createdAt - a.createdAt);
			})
			.addCase(fetchConsents.rejected, (state, action) => {
				state.fetchConsentsStatus = 'failed';
				state.error = action.payload.errorMessage;
			})
			// fetchConsents - END
			// setAccountPermissionForIndividualConsent - START
			.addCase(setAccountPermissionForIndividualConsent.pending, (state, action) => {
				state.setAccountPermissionForIndividualConsentStatus = 'loading';
				state.consents = state.consents.map((a) => {
					if (a.consentId === action.meta.arg.consentId) {
						return {
							...a,
							accounts: a.accounts?.map((acc) => {
								if (acc.accountId === action.meta.arg.accountId) {
									acc.availableForSharing = action.meta.arg.sharingPermission === globalConstants.DENIED ? false : true;
									acc.loading = true;
								}
								return acc;
							})
						};
					}
					return a;
				});
			})
			.addCase(setAccountPermissionForIndividualConsent.fulfilled, (state, action) => {
				state.setAccountPermissionForIndividualConsentStatus = 'succeeded';
				state.consents = state.consents.map((a) => {
					if (a.consentId === action.meta.arg.consentId) {
						return {
							...a,
							accounts: a.accounts?.map((acc) => {
								if (acc.accountId === action.meta.arg.accountId) {
									acc.availableForSharing = action.meta.arg.sharingPermission === globalConstants.DENIED ? false : true;
									acc.loading = false;
								}
								return acc;
							})
						};
					}
					return a;
				});
			})
			.addCase(setAccountPermissionForIndividualConsent.rejected, (state) => {
				state.setAccountPermissionForIndividualConsentStatus = 'failed';
				state.consents = state.consents.map((c) => {
					c.accounts?.map((acc) => {
						acc.loading = false;
						return acc;
					});
					return c;
				});
			})
			// setAccountPermissionForIndividualConsent - END
			// getConfirmationPdfFile - START
			.addCase(getConfirmationPdfFile.pending, (state) => {
				state.getConfirmationPdfFileStatus = 'loading';
			})
			.addCase(getConfirmationPdfFile.fulfilled, (state, action) => {
				state.getConfirmationPdfFileStatus = 'succeeded';
			})
			.addCase(getConfirmationPdfFile.rejected, (state) => {
				state.getConfirmationPdfFileStatus = 'failed';
			})
			// getConfirmationPdfFile - END
			// getWithdrawalPdfFile - START
			.addCase(getWithdrawalPdfFile.pending, (state) => {
				state.getWithdrawalPdfFileStatus = 'loading';
			})
			.addCase(getWithdrawalPdfFile.fulfilled, (state, action) => {
				state.getWithdrawalPdfFileStatus = 'succeeded';
			})
			.addCase(getWithdrawalPdfFile.rejected, (state) => {
				state.getWithdrawalPdfFileStatus = 'failed';
			})
			// getWithdrawalPdfFile - END
			// getHistoryPdfFile - START
			.addCase(getHistoryPdfFile.pending, (state, action) => {
				state.consents = state.consents.map((auth) => {
					if (auth.history) {
						for (let h of auth.history) {
							if (h.versionId === action.meta.arg.versionId) {
								h.fileLoading = true;
							}
						}
					}
					return auth;
				});
				state.historyFile = null;
			})
			.addCase(getHistoryPdfFile.fulfilled, (state) => {
				state.consents = state.consents.map((auth) => {
					if (auth.history) {
						for (let h of auth.history) {
							h.fileLoading = false;
						}
					}
					return auth;
				});
			})
			.addCase(getHistoryPdfFile.rejected, (state) => {
				state.consents = state.consents.map((auth) => {
					if (auth.history) {
						for (let h of auth.history) {
							h.fileLoading = false;
						}
					}
					return auth;
				});
			})
			// getHistoryPdfFile - END
			// stopSharingConsent - START
			.addCase(stopSharingConsent.pending, (state) => {
				state.stopSharingConsentStatus = 'loading';
				state.stopSharingSuccess = false;
			})
			.addCase(stopSharingConsent.fulfilled, (state, action) => {
				state.stopSharingConsentStatus = 'succeeded';
				state.stopSharingSuccess = true;
			})
			.addCase(stopSharingConsent.rejected, (state) => {
				state.stopSharingConsentStatus = 'failed';
			});
		// stopSharingConsent - END
	}
});

export const {
	setIsActiveSelected,
	resetConsents,
	setConsentsLoader,
	changeMenuItemsStatusNumber,
	changeMenuItems,
	restartAccountPermissionForIndividualConsent
} = consentSlice.actions;
export default consentSlice.reducer;
