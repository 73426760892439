import React from 'react';
import { dataLanguageStandards } from '../constants';

const ConsentComponent = React.memo(({ consent }) => {
	const getConditionItem = (scope, scopeIndex) => (
		<div key={scopeIndex}>
			{dataLanguageStandards.map((dataLanguageStandard, dataLanguageStandardIndex) => {
				// For common:customer scope, data requested should display differently for Personal and other profiles.
				// See the dataLanguageStandards profileType configuration to see which is personal and which is business
				if (scope === dataLanguageStandard.authorisationScope) {
					if (dataLanguageStandard.profileType && !dataLanguageStandard.profileType.includes(consent.accountType)) {
						return null;
					}
					return (
						<div key={dataLanguageStandardIndex} className="consent-details-page__sharing-condition-item">
							<div className="consent-details-page__sharing-condition-item-title">{dataLanguageStandard.dataClusterLanguage}</div>
							{dataLanguageStandard.permissionLanguage.split(';').map((permissionLanguage, permissionLanguageIndex) => (
								<div key={permissionLanguageIndex} className="consent-details-page__sharing-condition-item-content">
									{permissionLanguage}
								</div>
							))}
						</div>
					);
				} else {
					return null;
				}
			})}
		</div>
	);

	if (consent) {
		return consent.scopes.split(' ').map((scope, scopeIndex) => {
			if (dataLanguageStandards.filter((a) => a.authorisationScope === scope).length > 0) {
				return getConditionItem(scope, scopeIndex);
			} else {
				return null;
			}
		});
	} else {
		return <div></div>;
	}
});

export default ConsentComponent;
