import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { globalConstants } from '../../constants';
import { Button } from '../../basiqComponents';
import { DividerComponent, HeaderComponent, RadioButtonComponent } from '../../components';
import classnames from 'classnames';
import { selectAllAccounts } from '../../store/slices/account/selectors';
import { restartGlobalPermissionForAnAccount, setAccountsLoader } from '../../store/slices/account/slice';

const AccountDetailsPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const allAccounts = useSelector(selectAllAccounts);
	const [radioButtonSelectedValue, setRadioButtonSelectedValue] = useState();
	const accountId = useParams().id;
	let account = allAccounts && allAccounts.find((account) => account.accountId === accountId);
	const accountsPageInnerClassName = classnames('accounts-page__inner', account?.permissionChangeRequestPending && 'accounts-page__inner--pending');

	useEffect(() => {
		account && setRadioButtonSelectedValue(account.sharingPermission);
	}, [account]);

	const handleSubmit = () => {
		if (radioButtonSelectedValue === globalConstants.ALLOWED) {
			navigate(`/accounts/${account.accountId}/confirmation/pre-approved`);
		} else {
			navigate(`/accounts/${account.accountId}/confirmation/do-not-share`);
		}
	};

	const handleBack = () => {
		dispatch(restartGlobalPermissionForAnAccount());
		dispatch(setAccountsLoader());
		navigate('/accounts');
	};

	return (
		<>
			<HeaderComponent onBack={handleBack} />
			<div className="accounts-page accounts-page__wrapper">
				<>
					<div className={accountsPageInnerClassName}>
						<div className="accounts-page__content-text">
							<span className="bottom-navigation-bar__icon icon-settings"></span>
							<span>{t('label.change_data_sharing_permission')}</span>
						</div>
						{account?.permissionChangeRequestPending && (
							<div className="accounts-page__request-pending">
								<span className="icon-alert-circle"></span>
								<div className="accounts-page__request-pending-inner">
									<label>{t('label.request_pending')}</label>
									<label>{t('label.change_the_data_sharing_permission')}</label>
								</div>
							</div>
						)}
						<DividerComponent />
						<div className="accounts-page__account-name-number">
							<span>{account?.displayName}</span>
							<span>{account?.maskedNumber}</span>
						</div>
						<label className="accounts-page__content-text accounts-page__held-text">{t('label.held_with_other_account')}</label>
						<div className="accounts-page__checkbox-wrapper">
							<label className="accounts-page__content-text">{t('label.pre_approved_sharing')}</label>
							<RadioButtonComponent
								name={globalConstants.SHARING}
								checked={radioButtonSelectedValue === globalConstants.ALLOWED}
								value={globalConstants.ALLOWED}
								onChange={() => setRadioButtonSelectedValue(globalConstants.ALLOWED)}
							/>
						</div>
						<DividerComponent />
						<div className="accounts-page__checkbox-wrapper">
							<label className="accounts-page__content-text">{t('label.do_not_share')}</label>
							<RadioButtonComponent
								name={globalConstants.SHARING}
								checked={radioButtonSelectedValue === globalConstants.DENIED}
								value={globalConstants.DENIED}
								onChange={() => setRadioButtonSelectedValue(globalConstants.DENIED)}
							/>
						</div>
					</div>
					{!account?.permissionChangeRequestPending && (
						<div className="content-wrapper__bottom accounts-page__buttons-wrapper">
							<Button
								id="account-details-go-back-button"
								type="button"
								text={t('label.no_go_back')}
								theme="secondary-dark"
								spanClassName="primary-text"
								onClick={() => navigate('/accounts')}
							/>
							<Button
								id="account-details-confirm-button"
								type="submit"
								text={t('label.confirm')}
								spanClassName="primary-text"
								theme="secondary-dark"
								disabled={
									(account?.sharingPermission === globalConstants.ALLOWED &&
										radioButtonSelectedValue === globalConstants.ALLOWED) ||
									(account?.sharingPermission === globalConstants.DENIED && radioButtonSelectedValue === globalConstants.DENIED)
								}
								onClick={handleSubmit}
							/>
						</div>
					)}
				</>
			</div>
		</>
	);
};

export default withTranslation()(AccountDetailsPage);
