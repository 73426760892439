import { createSelector } from '@reduxjs/toolkit';

const selectSettingsData = (state) => state.settings;

// fetchSettings
export const selectRepresentativesLoading = createSelector(selectSettingsData, (t) => t?.fetchRepresentativesStatus === 'loading');
export const selectRepresentatives = createSelector(selectSettingsData, (t) => t?.representatives);
export const selectAccountsWithNoRepresentatives = createSelector(selectSettingsData, (t) => t?.accountsWithNoRepresentatives);

// fetchNotificationsSettings
export const selectNotificationsSettingsLoading = createSelector(selectSettingsData, (t) => t?.fetchNotificationsSettingsStatus === 'loading');
export const selectSendNotifications = createSelector(selectSettingsData, (t) => t?.sendNotifications);
export const selectFrequency = createSelector(selectSettingsData, (t) => t?.frequency);

// setNotificationsSettings
export const selectSetNotificationsSettingsLoading = createSelector(selectSettingsData, (t) => t?.setNotificationsSettingsStatus === 'loading');
export const selectNotificationsSettingsChanged = createSelector(selectSettingsData, (t) => t?.notificationsSettingsChanged);

// fetchSecondaryUsers
export const selectSecondaryUsersStatusLoading = createSelector(selectSettingsData, (t) => t?.fetchSecondaryUsersStatus === 'loading');
export const selectSecondaryUsers = createSelector(selectSettingsData, (t) => t?.secondaryUsers);

// changeSecondaryUserAccountStatus
export const selectUserAccountStatusChanging = createSelector(selectSettingsData, (t) => t?.changeSecondaryUserAccountStatus === 'loading');
