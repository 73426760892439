import React from 'react';
import { withTranslation } from 'react-i18next';

const DescriptionComponent = ({ t, companyName, title, content }) => (
	<div className="description-component">
		{companyName ? (
			<div className="description-component-company-name-wrapper">
				<div className="description-component-connect-to">{t('label.connect_to')}</div>
				<div className="description-component-title">{companyName}</div>
			</div>
		) : (
			<div className="description-component-title">{title}</div>
		)}
		<div className="description-component-content">{content}</div>
	</div>
);

export default withTranslation()(DescriptionComponent);
