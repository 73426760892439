import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { RadioButtonComponent, DividerComponent, HeaderComponent, LoaderComponent } from '../../components';
import { isNull } from '../../helpers';
import { globalConstants } from '../../constants';
import { Button } from '../../basiqComponents';
import classnames from 'classnames';
import { selectMemberId } from '../../store/slices/member/selectors';
import {
	selectNotificationsSettingsLoading,
	selectFrequency,
	selectNotificationsSettingsChanged,
	selectSendNotifications,
	selectSetNotificationsSettingsLoading
} from '../../store/slices/settings/selectors';
import { fetchNotificationsSettings, setNotificationsSettings } from '../../store/slices/settings/thunks';
import { resetNotificationsSettings } from '../../store/slices/settings/slice';

const SettingsNotificationPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const memberId = useSelector(selectMemberId);
	const notificationsSettingsLoading = useSelector(selectNotificationsSettingsLoading);
	const sendNotifications = useSelector(selectSendNotifications);
	const frequency = useSelector(selectFrequency);
	const notificationsSettingsChanged = useSelector(selectNotificationsSettingsChanged);
	const setNotificationsSettingsLoading = useSelector(selectSetNotificationsSettingsLoading);

	const [radioButtonAllNotificationsSelectedValue, setRadioButtonAllNotificationsSelectedValue] = useState(false);
	const [radioButtonFrequencySelectedValue, setRadioButtonFrequencySelectedValue] = useState('');
	const [allNotificationsShowMore, setAllNotificationsShowMore] = useState(false);
	const [frequencyShowMore, setFrequencyShowMore] = useState(false);

	const frequencyWrapperClassName = classnames(
		'settings-page__show-more-wrapper',
		radioButtonAllNotificationsSelectedValue === false && 'settings-page__show-more-wrapper--disabled'
	);
	const frequencyCheckboxWrapperClassName = classnames(
		'settings-page__checkbox-wrapper-outer',
		radioButtonAllNotificationsSelectedValue === false && 'settings-page__checkbox-wrapper-outer--disabled'
	);

	useEffect(() => {
		dispatch(fetchNotificationsSettings(memberId));
	}, [dispatch, memberId]);

	useEffect(() => {
		if (!isNull(frequency) && !isNull(sendNotifications)) {
			setRadioButtonFrequencySelectedValue(frequency);
			setRadioButtonAllNotificationsSelectedValue(sendNotifications);
		}
	}, [frequency, sendNotifications]);

	useEffect(() => {
		if (notificationsSettingsChanged) {
			dispatch(resetNotificationsSettings());
			navigate('/settings');
		}
	}, [dispatch, navigate, notificationsSettingsChanged]);

	const handleContinueClick = (e) => {
		e.preventDefault();
		dispatch(
			setNotificationsSettings({
				memberId,
				sendNotifications: radioButtonAllNotificationsSelectedValue,
				frequency: radioButtonFrequencySelectedValue
			})
		);
	};

	const isButtonDisabled = () => radioButtonFrequencySelectedValue === frequency && radioButtonAllNotificationsSelectedValue === sendNotifications;

	const handleBack = () => {
		dispatch(resetNotificationsSettings());
		navigate('/settings');
	};

	return (
		<>
			<HeaderComponent onBack={handleBack} />
			<div className="settings-page">
				{notificationsSettingsLoading ? (
					<LoaderComponent />
				) : (
					<div className="settings-page__inner settings-page__notification-settings">
						<label className="settings-page__settings-text">{t('label.notification_preferences')}</label>
						<label className="settings-page__joint-account">{t('label.for_joint_accounts_only')}</label>
						<DividerComponent />
						{allNotificationsShowMore && (
							<div className="settings-page__show-more-wrapper-outer">
								<label>{t('label.notifications_are_sent_to_all')}</label>
								<ul>
									<li>{t('label.created')}</li>
									<li>{t('label.amended')}</li>
									<li>{t('label.cancelled')}</li>
									<li>{t('label.expired')}</li>
								</ul>
								<div className="settings-page__info-wrapper">
									<div className="settings-page__icon-wrapper">
										<span className="icon-info"></span>
										<label>{t('label.if_you_turn_off')}</label>
									</div>
									<div className="settings-page__text-wrapper">
										<label>{t('label.you_wont_receive')}</label>
										<div>
											<label>{t('label.this_does')}</label>
											<span className="settings-page__not">{t('label.not')}</span>
											<label>{t('label.include_joint_account')}</label>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="settings-page__show-more-wrapper">
							<label className="settings-page__settings-text">{t('label.all_notifications')}</label>
							<span
								id={allNotificationsShowMore ? 'all-notifications-less' : 'all-notifications-more'}
								onClick={() => setAllNotificationsShowMore(!allNotificationsShowMore)}>
								{allNotificationsShowMore ? t('label.show_less') : t('label.show_more')}
							</span>
						</div>
						<div className="settings-page__checkbox-wrapper-outer">
							<div className="settings-page__checkbox-wrapper">
								<label className="settings-page__content-text">{t('label.turn_on_notifications')}</label>
								<RadioButtonComponent
									id="turn-on-notifications"
									name={globalConstants.ALL_NOTIFICATIONS}
									checked={radioButtonAllNotificationsSelectedValue}
									value={true}
									onChange={() => {
										setRadioButtonAllNotificationsSelectedValue(true);
										setRadioButtonFrequencySelectedValue(globalConstants.INSTANTLY);
									}}
								/>
							</div>
							<div className="settings-page__checkbox-wrapper">
								<label className="settings-page__content-text">{t('label.turn_off_notifications')}</label>
								<RadioButtonComponent
									id="turn-off-notifications"
									name={globalConstants.ALL_NOTIFICATIONS}
									checked={!radioButtonAllNotificationsSelectedValue}
									value={false}
									onChange={() => {
										setRadioButtonAllNotificationsSelectedValue(false);
										setRadioButtonFrequencySelectedValue('');
									}}
								/>
							</div>
						</div>
						<DividerComponent />
						{frequencyShowMore && (
							<div className="settings-page__show-more-wrapper-outer">
								<div className="settings-page__info-wrapper">
									<div className="settings-page__icon-wrapper">
										<span className="icon-info"></span>
										<label>{t('label.if_you_change_the_frequency')}</label>
									</div>
									<div className="settings-page__text-wrapper">
										<div>{t('label.you_will_not_receive')}</div>
										<div>{t('label.for_data_sharing')}</div>
										<div>{t('label.this_type_of_requests')}</div>
									</div>
								</div>
							</div>
						)}
						<div className={frequencyWrapperClassName}>
							<label className="settings-page__settings-text">{t('label.frequency')}</label>
							<span
								id={frequencyShowMore ? 'frequency-less' : 'frequency-more'}
								onClick={() => setFrequencyShowMore(!frequencyShowMore)}>
								{!radioButtonAllNotificationsSelectedValue === false && (
									<>{frequencyShowMore ? t('label.show_less') : t('label.show_more')}</>
								)}
							</span>
						</div>
						<div className={frequencyCheckboxWrapperClassName}>
							<div className="settings-page__checkbox-wrapper">
								<label className="settings-page__content-text">{t('label.send_me_notifications')}</label>
								<RadioButtonComponent
									id="notifications-real-time"
									name={globalConstants.FREQUENCY}
									checked={radioButtonFrequencySelectedValue === globalConstants.INSTANTLY}
									disabled={radioButtonAllNotificationsSelectedValue === false}
									onChange={() => setRadioButtonFrequencySelectedValue(globalConstants.INSTANTLY)}
								/>
							</div>
							<div className="settings-page__checkbox-wrapper">
								<label className="settings-page__content-text">{t('label.only_send_me')}</label>
								<RadioButtonComponent
									id="notifications-monthly"
									name={globalConstants.FREQUENCY}
									checked={radioButtonFrequencySelectedValue === globalConstants.MONTHLY}
									disabled={radioButtonAllNotificationsSelectedValue === false}
									onChange={() => setRadioButtonFrequencySelectedValue(globalConstants.MONTHLY)}
								/>
							</div>
						</div>
						<Button
							id="notification-settings-continue-button"
							type="submit"
							text={t('label.save_changes')}
							theme="secondary-dark"
							disabled={isButtonDisabled()}
							loading={setNotificationsSettingsLoading}
							onClick={handleContinueClick}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default withTranslation()(SettingsNotificationPage);
