import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchAccounts = createAsyncThunk('member/fetchAccounts', async ({ memberId, profileId }, { rejectWithValue }) => {
	try {
		const response = await axios.get(`${memberId}/accounts?profileId=${profileId}`);
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const setGlobalPermissionForAnAccount = createAsyncThunk(
	'member/setGlobalPermissionForAnAccount',
	async ({ memberId, accountId, sharingPermission, notificationId }, { rejectWithValue }) => {
		try {
			const response = await axios.post(`${memberId}/accounts/${accountId}`, { sharingPermission, notificationId });
			return response?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
