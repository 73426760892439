import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { globalConstants } from '../../constants';
import { ConfirmationHeaderComponent, HeaderComponent } from '../../components';
import { notificationTypeAllowedDeniedCheck, notificationTypePendingApprovalCheck, notificationTypeSingleAccountDOCheck } from '../../helpers';
import { Button } from '../../basiqComponents';
import { selectNotifications } from '../../store/slices/notification/selectors';

const NotificationDetailsPage = ({ t }) => {
	const navigate = useNavigate();
	const notifications = useSelector(selectNotifications);
	const notificationId = useParams().id;
	const notification = notifications?.find((notification) => notification?.notificationId === notificationId);
	const isPending = notification?.approvalStatus === globalConstants.PENDING;

	const isChangeRequestExpired = notification?.type === globalConstants.CHANGE_REQUEST_EXPIRED;
	const iconAlertCircleClassName = classnames('icon-alert-circle', (!isPending || notification?.isExpired) && 'icon-alert-circle--inactive');

	return (
		<>
			<HeaderComponent onBack={() => navigate('/notifications')} />
			<div className="notifications-page__details">
				{notification && (
					<>
						<div className="notifications-page__body-wrapper">
							{notificationTypePendingApprovalCheck(notification.type) || isChangeRequestExpired ? (
								<ConfirmationHeaderComponent
									icon={iconAlertCircleClassName}
									label={
										isChangeRequestExpired
											? t('label.disclosure_option_change_request_expired')
											: t('label.disclosure_option_change')
									}
									contentLabel={notification.accountName}
								/>
							) : notificationTypeSingleAccountDOCheck(notification.type) ? (
								<div className="notifications-page__body-title">
									{t('label.sharing')}{' '}
									{notification.type === globalConstants.ALLOWED_SINGLE
										? t('label.enabled').toLowerCase()
										: t('label.disabled').toLowerCase()}
								</div>
							) : notificationTypeAllowedDeniedCheck(notification.type) ? (
								<div className="notifications-page__body-title">{t('label.data_sharing_permission_changed')}</div>
							) : notification.type === globalConstants.DECLINED ? (
								<div className="notifications-page__body-title">{t('label.data_sharing_permission_declined')}</div>
							) : (
								<div className="notifications-page__body-title">{notification.detailsConsentText}</div>
							)}
							<div className="notifications-page__body">
								<div>{notification.body}</div>
							</div>
						</div>
						{notificationTypePendingApprovalCheck(notification.type) &&
							notification.approvalStatus === globalConstants.PENDING &&
							!notification.isExpired && (
								<div className="content-wrapper__bottom notifications-page__buttons-wrapper">
									<Button
										id="decline-button"
										type="button"
										text={t('label.decline')}
										theme="secondary-dark"
										spanClassName="primary-text"
										onClick={() => navigate(`/notifications/${notificationId}/details/decline`)}
									/>
									<Button
										id="approve-button"
										type="submit"
										text={t('label.approve')}
										spanClassName="primary-text"
										theme="secondary-dark"
										onClick={() => navigate(`/notifications/${notificationId}/details/approve`)}
									/>
								</div>
							)}
					</>
				)}
			</div>
		</>
	);
};

export default withTranslation()(NotificationDetailsPage);
