import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { HeaderComponent } from '../components';
import sessionTimedOut from '../img/session-timed-out.png';

const ErrorSessionTimedOutPage = ({ t }) => {
	useEffect(() => {
		document.title = 'Error session timed out page';
	}, []);

	return (
		<div className="main-page error-session-page">
			<HeaderComponent />
			<div className="error-session-page__content-wrapper">
				<div className="error-session-page__session-wrapper">
					<img
						className="error-session-page__session-timed-out-logo"
						height="41"
						width="41"
						alt="Session timed out Logo"
						src={sessionTimedOut}
					/>
					<div className="error-session-page__session-timed-out">{t('label.session_timed_out')}</div>
				</div>
				<div className="error-session-page__content-text">
					<span>{t('label.to_keep_your_data_secure')}</span>
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(ErrorSessionTimedOutPage);
