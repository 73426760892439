import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeaderComponent, LoaderComponent, SettingsExpandableItemComponent } from '../../components';
import { globalConstants } from '../../constants';
import { selectMemberId } from '../../store/slices/member/selectors';
import { selectSecondaryUsers, selectSecondaryUsersStatusLoading } from '../../store/slices/settings/selectors';
import { showUsersAccounts } from '../../store/slices/settings/slice';
import { changeSecondaryUserAccountStatus } from '../../store/slices/settings/thunks';

const SecondaryUserPermissionPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const memberId = useSelector(selectMemberId);
	const secondaryUsersLoading = useSelector(selectSecondaryUsersStatusLoading);
	const secondaryUsers = useSelector(selectSecondaryUsers);

	const handleDisableClick = (account, user) =>
		dispatch(
			changeSecondaryUserAccountStatus({
				memberId,
				accountId: account.accountId,
				secondaryUserMemberId: user.memberId,
				status: globalConstants.DISABLE,
				secondaryUserInstructionId: account.secondaryUserInstructionId
			})
		).then((status) => !status?.error && navigate('/settings/secondary-user-permission/disable'));

	const handleEnableClick = (account, user) =>
		dispatch(
			changeSecondaryUserAccountStatus({
				memberId,
				accountId: account.accountId,
				secondaryUserMemberId: user.memberId,
				status: globalConstants.ENABLE
			})
		).then((status) => !status?.error && navigate('/settings/secondary-user-permission/enable'));

	const handleBack = () => navigate('/settings');

	return (
		<>
			<HeaderComponent onBack={handleBack} />
			<div className="settings-page">
				<div className="settings-page__inner">
					{secondaryUsersLoading ? (
						<LoaderComponent />
					) : (
						<SettingsExpandableItemComponent
							text={t('label.secondary_user_permission')}
							header={t('label.a_secondary_user_is_a_nominated')}
							users={secondaryUsers}
							onClick={(user) => dispatch(showUsersAccounts({ memberId: user.memberId, open: !user.open }))}
							handleDisableClick={handleDisableClick}
							handleEnableClick={handleEnableClick}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default withTranslation()(SecondaryUserPermissionPage);
