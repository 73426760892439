import React from 'react';
import { withTranslation } from 'react-i18next';
import { ConfirmationHeaderComponent, HeaderComponent } from './';
import { Button } from '../basiqComponents';

const SettingsConfirmationComponent = ({ t, goBack, confirmationHeaderLabel, headerLabel, contentLabel, footerLabel, loading, onClick }) => (
	<>
		<HeaderComponent onBack={goBack} />
		<div className="settings-page__details">
			<>
				<div className="settings-page__body-wrapper">
					<ConfirmationHeaderComponent label={confirmationHeaderLabel} />
					<div className="settings-page__content-information">
						<div>{headerLabel}</div>
						<div>{contentLabel}</div>
						{footerLabel && <div className="bold">{footerLabel}</div>}
					</div>
				</div>
				<div className="content-wrapper__bottom accounts-page__buttons-wrapper">
					<Button
						id="okay-button"
						type="submit"
						text={t('label.okay')}
						theme="secondary-dark"
						spanClassName="primary-text"
						loading={loading}
						onClick={onClick}
					/>
				</div>
			</>
		</div>
	</>
);

export default withTranslation()(SettingsConfirmationComponent);
