import { createSlice } from '@reduxjs/toolkit';
import { sendError } from './thunks';

export const initialState = {};

const errorSlice = createSlice({
	name: 'error',
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			// sendError - START
			.addCase(sendError.pending, (state) => {})
			.addCase(sendError.fulfilled, (state, action) => {})
			.addCase(sendError.rejected, (state, action) => {});
		// sendError - END
	}
});

export default errorSlice.reducer;
