export const dataLanguageStandards = [
	// Individual Consumer - start
	{
		authorisationScope: 'common:customer.basic:read',
		permissionLanguage: 'Name;Occupation',
		dataClusterLanguage: 'Name and occupation',
		profileType: 'personal'
	},
	{
		authorisationScope: 'common:customer.detail:read',
		permissionLanguage: 'Phone;Email address;Mail address;Residential address;',
		dataClusterLanguage: 'Contact Details',
		profileType: 'personal'
	},
	{
		authorisationScope: 'common:customer.complete:read',
		permissionLanguage: 'Name;Occupation;Phone;Email address;Mail address;Residential address;',
		dataClusterLanguage: 'Name, occupation, contact details',
		profileType: 'personal'
	},
	// Individual Consumer - end
	// Business consumer - start
	{
		authorisationScope: 'common:customer.basic:read',
		permissionLanguage:
			'Agent name and role;Organisation name;Organisation numbers (ABN or ACN);Charity status;Establishment date;Industry;Organisation type;Country of registration',
		dataClusterLanguage: 'Organisation profile *',
		profileType: 'business,sole_trader'
	},
	{
		authorisationScope: 'common:customer.detail:read',
		permissionLanguage: 'Organisation address;Mail address;Phone number',
		dataClusterLanguage: 'Organisation contact details *',
		profileType: 'business,sole_trader'
	},
	{
		authorisationScope: 'common:customer.complete:read',
		permissionLanguage:
			'Agent name and role;Organisation name;Organisation numbers (ABN or ACN);Charity status;Establishment date;Industry;Organisation type;Country of registration;Organisation address;Mail address;Phone number',
		dataClusterLanguage: 'Organisation profile and contact details *',
		profileType: 'business,sole_trader'
	},
	// Business consumer - end
	// Common- start
	{
		authorisationScope: 'profile',
		permissionLanguage: 'Full name and title(s)',
		dataClusterLanguage: 'Name'
	},
	{
		authorisationScope: 'bank:accounts.basic:read',
		permissionLanguage: 'Name of account;Type of account;Account balance',
		dataClusterLanguage: 'Account name, type and balance'
	},
	{
		authorisationScope: 'bank:accounts.detail:read',
		permissionLanguage: 'Account number;Interest rates;Fees;Discounts;Account terms;Account mail address',
		dataClusterLanguage: 'Account numbers and features'
	},
	{
		authorisationScope: 'bank:accounts.complete:read',
		permissionLanguage:
			'Name of account;Type of account;Account balance;Account number;Interest rates;Fees;Discounts;Account terms;Account mail address;',
		dataClusterLanguage: 'Account balance and details'
	},
	{
		authorisationScope: 'bank:transactions:read',
		permissionLanguage:
			'Incoming and outgoing transactions;Amounts;Dates;Descriptions of transactions;Who you have sent money to and received money from - (e.g. their name)',
		dataClusterLanguage: 'Transaction details'
	},
	{
		authorisationScope: 'bank:regular_payments:read',
		permissionLanguage: 'Direct debits;Scheduled payments',
		dataClusterLanguage: 'Direct debits and scheduled payments'
	},
	{
		authorisationScope: 'bank:payees:read',
		permissionLanguage:
			'Names and details of accounts you have saved; (e.g. their BSB and Account Number, BPay CRN and Biller code, or NPP PayID)',
		dataClusterLanguage: 'Saved payees'
	}
	// Common- end
];
