import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { globalConstants } from '../../../constants';

export const fetchMemberDetails = createAsyncThunk('member/fetchMemberDetails', async ({ memberId, dateOfBirth }, { rejectWithValue }) => {
	try {
		const response = await axios.post(`login`, { memberId, dateOfBirth, action: globalConstants.LOGIN_ACTION });
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const authenticate = createAsyncThunk('member/authenticate', async ({ memberId, code }, { rejectWithValue }) => {
	try {
		const response = await axios.post(`${memberId}/code`, { code });
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});

export const resendOtpCode = createAsyncThunk('member/resendOtpCode', async ({ memberId, dateOfBirth }, { rejectWithValue }) => {
	try {
		const response = await axios.post(`login`, { memberId, dateOfBirth, action: globalConstants.RESEND_ACTION });
		return response?.data;
	} catch (error) {
		return rejectWithValue(error.response.data);
	}
});
