import { createBrowserHistory } from 'history';
import { globalConstants } from '../constants';
import { sendError } from '../store/slices/error/thunks';

const history = createBrowserHistory();

export const changeRoute = (route = '/') => window.location.replace(route);

export const getLocation = () => {
	return history.location;
};

export const getHistory = () => {
	return history;
};

export const isNull = (object) => {
	return object === null || object === undefined;
};

export const isNotZero = (number) => {
	return number !== undefined && number !== 0;
};

export const getLastPartFromUrl = (url) => {
	try {
		const splitUrl = url.split('/');
		return splitUrl[splitUrl.length - 1];
	} catch (error) {
		sendError('utils - getLastPartFromUrl() - ', error);
	}
};

export const getPdfLink = (pdfLink) => {
	try {
		if (window.location.hostname.includes('sit')) {
			return `https://infosec-api.sit.peopleschoice.dh.basiq.io/mock-oidc/pdf/${pdfLink}`;
		} else {
			// UAT
			return `https://infosec-api.uat.peopleschoice.dh.basiq.io/mock-oidc/pdf/${pdfLink}`;
		}
	} catch (error) {
		sendError('utils - getPdfLink() - ', error);
	}
};

export const capitalizeFirstLetter = (string) => {
	try {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} catch (error) {
		sendError('utils - capitalizeFirstLetter() - ', error);
		return '';
	}
};

export const getExpiresIn = (seconds) => {
	try {
		const expiresAt = new Date(seconds * 1000);
		const now = new Date();
		return `0:${Math.floor((expiresAt - now) / 60000)}`;
	} catch (error) {
		sendError('utils - getExpiresIn() - ', error);
		return '';
	}
};

export const notificationTypePendingApprovalCheck = (type) => type === globalConstants.CHANGE_REQUEST || type === globalConstants.PENDING_APPROVAL;

export const notificationTypeSingleAccountDOCheck = (type) => type === globalConstants.ALLOWED_SINGLE || type === globalConstants.DENIED_SINGLE;

export const notificationTypeAllowedDeniedCheck = (type) => type === globalConstants.ALLOWED || type === globalConstants.DENIED;

export const downloadPdf = (data, fileName) => {
	try {
		let a = document.createElement('a');
		document.body.appendChild(a);
		a.style = 'display: none';
		const url = window.URL.createObjectURL(data);
		a.href = url;
		a.download = fileName;
		a.click();
		window.URL.revokeObjectURL(url);
		a.remove();
	} catch (error) {
		sendError('utils - downloadPdf() - ', error);
	}
};

export const millisecondsToMinutesAndSeconds = (milliseconds) => {
	try {
		const minutes = Math.floor(milliseconds / 60000);
		const seconds = +((milliseconds % 60000) / 1000).toFixed(0);
		return { minutes, seconds };
	} catch (error) {
		sendError('utils - millisecondsToMinutesAndSeconds() - ', error);
		return { minutes: 0, seconds: 0 };
	}
};

export const validateDateOfBirth = (dateString) => {
	try {
		// Adjust regex to allow years greater than the current year
		const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;
		if (regex.test(dateString)) {
			const [day, month, year] = dateString.split('/').map((num) => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			const currentYear = new Date().getFullYear();

			if (year >= 1900 && year <= currentYear) {
				return date && date.getMonth() + 1 === month && date.getDate() === day;
			}
		}
		return false;
	} catch (error) {
		sendError('utils - validateDateOfBirth() - ', error);
		return false;
	}
};
