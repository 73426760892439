import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { DividerComponent, HeaderComponent } from '../../components';
import { setAccountsLoader } from '../../store/slices/account/slice';
import { selectAllAccounts } from '../../store/slices/account/selectors';

const AccountConfirmationDeclinedPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const allAccounts = useSelector(selectAllAccounts);
	const accountId = useParams().id;
	let account = allAccounts && allAccounts.find((account) => account.accountId === accountId);

	return (
		<>
			<HeaderComponent
				onBack={() => {
					dispatch(setAccountsLoader());
					navigate('/accounts');
				}}
			/>
			<div className="accounts-page accounts-page__wrapper">
				<div className="accounts-page__inner">
					<label className="accounts-page__content-text">
						<span className="icon-checkmark-outline"></span>
						<span className="accounts-page__header-text">{t('label.data_sharing_permission_has_been_changed')}</span>
					</label>
					<DividerComponent />
					<div className="accounts-page__confirmed-account-wrapper">
						<div>{t('label.joint_account_data_sharing', { accountName: account?.displayName })}</div>
						<div>{t('label.data_sharing_is_no_longer_available')}</div>
						<div>{t('label.a_confirmation_sms_has_been_sent')}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(AccountConfirmationDeclinedPage);
