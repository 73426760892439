import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { isNotZero } from '../helpers';
import { globalConstants } from '../constants';
import { changeMenuItems } from '../store/slices/consent/slice';
import { selectMenuItems } from '../store/slices/consent/selectors';
import { resetNotifications } from '../store/slices/notification/slice';
import { restartGlobalPermissionForAnAccount, setAccountsLoader } from '../store/slices/account/slice';

const BottomNavigationBar = ({ t }) => {
	const dispatch = useDispatch();
	const menuItems = useSelector(selectMenuItems);
	const getMenuItem = () => menuItems.find((menuItem) => window.location?.pathname?.includes(menuItem.route));

	useEffect(() => {
		dispatch(changeMenuItems(getMenuItem()));
	}, [dispatch]);

	return (
		<div className="bottom-navigation-bar">
			<div className="bottom-navigation-bar__inner-wrapper">
				{menuItems?.map((menuItem, index) => {
					const iconClassName = classnames('bottom-navigation-bar__icon', `icon-${menuItem.iconName}`);
					const navClassName = classnames(menuItem.active && 'is-active');
					return (
						<NavLink
							onClick={() => {
								dispatch(changeMenuItems(menuItem));
								dispatch(restartGlobalPermissionForAnAccount());
								dispatch(setAccountsLoader());
								if (!menuItem.tab.includes(globalConstants.NOTIFICATIONS)) {
									dispatch(resetNotifications());
								}
							}}
							className={navClassName}
							key={index}
							to={menuItem.route}>
							<span className={iconClassName}></span>
							<label>{t(menuItem.label)}</label>
							{isNotZero(menuItem.statusNumber) && <div className="bottom-navigation-bar__status-number">{menuItem.statusNumber}</div>}
						</NavLink>
					);
				})}
			</div>
		</div>
	);
};

export default withTranslation()(BottomNavigationBar);
