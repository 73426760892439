import { createSelector } from '@reduxjs/toolkit';
import i18n from '../../../locales/i18n';
import { sendError } from '../error/thunks';
import moment from 'moment-timezone';
import { globalConstants } from '../../../constants';

const getDateText = (type) => {
	switch (type) {
		case 'Created':
			return 'date';
		case 'Agreement':
			return 'date';
		case 'Amendment':
			return 'amended';
		case 'Expiry':
			return 'end_date';
		case 'Revocation':
			return 'date';
		default:
			return 'date';
	}
};

const getNotificationType = (type) => (type === globalConstants.REVOCATION ? globalConstants.WITHDRAWN : type);

const selectNotificationData = (state) => state.notification;

// fetchNotifications
export const selectNotificationsLoading = createSelector(selectNotificationData, (t) => t?.fetchNotificationsStatus === 'loading');
export const selectNotifications = createSelector(selectNotificationData, (t) =>
	t?.notifications?.map((notification) => {
		try {
			let isExpired = moment().isAfter(moment(notification.expiresAt * 1000));
			let createdAtFormated = moment(notification.createdAt * 1000).format('DD MMM YYYY');
			let expiresAtFormated = moment(notification.expiresAt * 1000).format('DD MMM YYYY');
			let dateText = `${i18n.t(`label.${getDateText(notification.type)}`)}: ${createdAtFormated}`;
			let expiresAtText = `${i18n.t('label.expires')}: ${expiresAtFormated}`;
			let headerConsentText = `${i18n.t('label.consent')} ${getNotificationType(notification.type)} - ${notification.adrName}`;
			let detailsConsentText = `${i18n.t('label.consent')} ${getNotificationType(notification.type)}`;
			return {
				...notification,
				createdAtFormated,
				dateText,
				isExpired,
				expiresAtText,
				headerConsentText,
				detailsConsentText
			};
		} catch (error) {
			sendError('notification - selectors - selectNotifications - ', error);
			return notification;
		}
	})
);
export const selectUnreadCount = createSelector(selectNotificationData, (t) => t?.unreadCount);

// markNotificationAsRead
export const selectMarkNotificationAsReadStatus = createSelector(selectNotificationData, (t) => t?.markNotificationAsReadStatus);
