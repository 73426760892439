import { createSlice } from '@reduxjs/toolkit';
import { globalConstants } from '../../../constants';
import { fetchNotifications, markNotificationAsRead } from './thunks';

export const initialState = {
	notifications: [],
	fetchNotificationsStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	unreadCount: 0
};

const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		resetNotifications: (state) => {
			state.notifications = [];
			state.unreadCount = 0;
			state.fetchNotificationsStatus = 'loading';
		}
	},
	extraReducers(builder) {
		builder
			// fetchNotifications - START
			.addCase(fetchNotifications.pending, (state) => {
				state.fetchNotificationsStatus = 'loading';
				state.notifications = [];
				state.unreadCount = 0;
			})
			.addCase(fetchNotifications.fulfilled, (state, action) => {
				state.fetchNotificationsStatus = 'succeeded';
				state.notifications = action.payload.notifications;
				state.unreadCount = action.payload.unreadCount;
			})
			.addCase(fetchNotifications.rejected, (state, action) => {
				state.fetchNotificationsStatus = 'failed';
				state.error = action.payload.errorMessage;
			})
			// fetchNotifications - END
			// markNotificationAsRead - START
			.addCase(markNotificationAsRead.pending, (state) => {
				state.markNotificationAsReadStatus = 'loading';
			})
			.addCase(markNotificationAsRead.fulfilled, (state, action) => {
				state.markNotificationAsReadStatus = 'succeeded';
				state.notifications = state.notifications.map((notification) => {
					if (notification.notificationId === action.meta.arg.notificationId) {
						notification.status = globalConstants.READ;
					}
					return notification;
				});
				state.unreadCount = action.meta.arg.unreadCount - 1; //action.payload.unreadCount - 1;
			})
			.addCase(markNotificationAsRead.rejected, (state, action) => {
				state.markNotificationAsReadStatus = 'failed';
				state.error = action.payload.errorMessage;
			});
		// markNotificationAsRead - END
	}
});

export const { resetNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
