import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HeaderComponent } from '../../components';
import classnames from 'classnames';
import { selectProfile } from '../../store/slices/profile/slice';
import { selectConsents, selectIsActiveSelected } from '../../store/slices/consent/selectors';
import { selectSkipProfileScreen } from '../../store/slices/profile/selectors';
import { setIsActiveSelected } from '../../store/slices/consent/slice';

const ConsentContentPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const consents = useSelector(selectConsents);
	const skipProfileScreen = useSelector(selectSkipProfileScreen);
	const isActiveSelected = useSelector(selectIsActiveSelected);

	const activeClassName = classnames('consents-page__active', isActiveSelected && 'consents-page__active--selected');
	const archivedClassName = classnames('consents-page__archived', !isActiveSelected && 'consents-page__archived--selected');
	const activeButtonTextClassName = classnames('consents-page__buttons-text', isActiveSelected && 'consents-page__buttons-text--selected');
	const archivedButtonTextClassName = classnames('consents-page__buttons-text', !isActiveSelected && 'consents-page__buttons-text--selected');

	useEffect(() => {
		document.title = 'Consents page';
	}, []);

	const handleOnBack = () => {
		dispatch(selectProfile());
		navigate('/profile-selection');
		dispatch(setIsActiveSelected(true));
	};

	return (
		<>
			<HeaderComponent onBack={!skipProfileScreen && handleOnBack} />
			<div className="consents-page__buttons">
				<div className={activeClassName} onClick={() => dispatch(setIsActiveSelected(true))}>
					<span className="icon-checkmark-circle-outline"></span>
					<span className={activeButtonTextClassName}>{t('label.active')}</span>
				</div>
				<div className={archivedClassName} onClick={() => dispatch(setIsActiveSelected(false))}>
					<span className="icon-archive"></span>
					<span className={archivedButtonTextClassName}>{t('label.archived')}</span>
				</div>
				<div></div>
			</div>
			<div className="consents-page__content">
				{consents
					.filter((a) => (isActiveSelected ? a.status === 'active' : a.status !== 'active'))
					.map((consent, index) => {
						const consentClassName = classnames('consents-page__consent', !consent.isOwner && 'consents-page__consent--partial');
						return (
							<div className={consentClassName} key={index} onClick={() => navigate(`/consents/${consent.consentId}/details`)}>
								<div className="consents-page__consent-inner">
									<div className="consents-page__logo-wrapper">
										<img alt={consent.orgName} src={consent.orgLogoUri} />
									</div>
									<div className="consents-page__content-wrapper">
										<div>{consent.orgName}</div>
										<div>
											{consent.accounts?.map((account, accountIndex) => (
												<div className="consents-page__account-wrapper" key={accountIndex}>
													<div className="consents-page__account-display-name">{account.maskedNumber}</div>
												</div>
											))}
										</div>
									</div>
									<div className="consents-page__manage-wrapper">
										<span>{!consent.manageConsent ? t('label.view') : t('label.manage')}</span>
										<span className="icon-arrow-ios-forward"></span>
									</div>
								</div>
								<div className="consents-page__consent-inner">
									<div className="consents-page__logo-wrapper"></div>
									<div className="consents-page__content-wrapper">
										<div>{t('label.start_date')}</div>
										<div>{t('label.end_date')}</div>
										<div>{t('label.status')}</div>
									</div>
									<div className="consents-page__text-wrapper">
										<div>{consent.createdAtFormated}</div>
										<div>{consent.expiresAtFormated}</div>
										<div>{t(consent.statusText)}</div>
									</div>
								</div>
							</div>
						);
					})}
			</div>
		</>
	);
};

export default withTranslation()(ConsentContentPage);
