// hookUtils.js
import { useSelector } from 'react-redux';
import { getItem } from './storage';
import { globalConstants } from '../constants';
import { selectBrand } from '../store/slices/member/selectors';

// Custom hook to get a value from Redux state with a fallback to localStorage
export const useSelectorWithLocalStorage = ({ selector, localStorageKey }) => useSelector(selector) || getItem(localStorageKey);
export const useHookGetBrand = () => useSelectorWithLocalStorage({ selector: selectBrand, localStorageKey: globalConstants.BRAND });
export const useHookIsHBLBrand = () => useHookGetBrand() === globalConstants.HBL;
