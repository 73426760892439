import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ConfirmationHeaderComponent, HeaderComponent } from '../../components';
import { useNavigate } from 'react-router-dom';
import { restartGlobalPermissionForAnAccount } from '../../store/slices/account/slice';
import { selectGlobalPermissionForAnAccountChanged } from '../../store/slices/account/selectors';

const NotificationPendingPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const globalPermissionForAnAccountChanged = useSelector(selectGlobalPermissionForAnAccountChanged);
	const goBack = () => navigate('/notifications/');

	useEffect(() => {
		globalPermissionForAnAccountChanged && dispatch(restartGlobalPermissionForAnAccount());
	}, [dispatch, globalPermissionForAnAccountChanged]);

	return (
		<>
			<HeaderComponent onBack={goBack} />
			<div className="notifications-page__details">
				<div className="notifications-page__body-wrapper">
					<ConfirmationHeaderComponent icon="icon-checkmark-outline" label={t('label.what_happens_next')} />
					<div className="notifications-page__notification-information">
						<div>{t('label.the_request_to_change_the_data_sharing')}</div>
						<div>{t('label.as_an_account_holder')}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(NotificationPendingPage);
