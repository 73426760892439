import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { DividerComponent, HeaderComponent } from '../../components';
import { setAccountsLoader } from '../../store/slices/account/slice';

const AccountConfirmationApprovedPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<>
			<HeaderComponent
				onBack={() => {
					dispatch(setAccountsLoader());
					navigate('/accounts');
				}}
			/>
			<div className="accounts-page accounts-page__wrapper">
				<div className="accounts-page__inner">
					<label className="accounts-page__content-text">
						<span className="icon-checkmark-outline"></span>
						<span className="accounts-page__header-text">{t('label.what_happens_next')}</span>
					</label>
					<DividerComponent />
					<div className="accounts-page__confirmed-account-wrapper">
						<div>{t('label.the_request_to_change_the_data_sharing_from_pre_approved')}</div>
						<div>{t('label.as_an_account_holder')}</div>
						<div>{t('label.until_this_time_the_request_will_remain')}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(AccountConfirmationApprovedPage);
