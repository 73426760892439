import { createSlice } from '@reduxjs/toolkit';
import { fetchAccounts, setGlobalPermissionForAnAccount } from './thunks';

export const initialState = {
	allAccounts: [],
	availableForSharing: [],
	unavailableForSharing: [],
	fetchAccountsStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	isApproved: false,
	sharingPermission: ''
};

const consentSlice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		restartGlobalPermissionForAnAccount: (state) => {
			state.globalPermissionForAnAccountChanged = false;
			state.sharingPermission = '';
		},
		setAccountsLoader: (state) => {
			state.fetchAccountsStatus = 'loading';
		}
	},
	extraReducers(builder) {
		builder
			// fetchAccounts - START
			.addCase(fetchAccounts.pending, (state) => {
				state.fetchAccountsStatus = 'loading';
				state.allAccounts = [];
				state.availableForSharing = [];
				state.unavailableForSharing = [];
				state.globalPermissionForAnAccountChanged = false;
			})
			.addCase(fetchAccounts.fulfilled, (state, action) => {
				state.fetchAccountsStatus = 'succeeded';
				state.allAccounts = action.payload.availableForSharing.concat(action.payload.unavailableForSharing);
				state.availableForSharing = action.payload.availableForSharing;
				state.unavailableForSharing = action.payload.unavailableForSharing;
			})
			.addCase(fetchAccounts.rejected, (state) => {
				state.fetchAccountsStatus = 'failed';
			})
			// fetchAccounts - END
			// setGlobalPermissionForAnAccount - START
			.addCase(setGlobalPermissionForAnAccount.pending, (state, action) => {
				state.setGlobalPermissionForAnAccountStatus = 'loading';
				state.globalPermissionForAnAccountChanged = false;
				state.sharingPermission = action.meta.arg.sharingPermission;
				state.isApproved = false;
			})
			.addCase(setGlobalPermissionForAnAccount.fulfilled, (state, action) => {
				state.setGlobalPermissionForAnAccountStatus = 'succeeded';
				state.globalPermissionForAnAccountChanged = true;
				state.sharingPermission = '';
				state.isApproved = action.payload.isApproved;
			})
			.addCase(setGlobalPermissionForAnAccount.rejected, (state, action) => {
				state.setGlobalPermissionForAnAccountStatus = 'failed';
				state.sharingPermission = '';
				state.error = action.payload.error;
			});
		// setGlobalPermissionForAnAccount - END
	}
});

export const { resetConsents, restartGlobalPermissionForAnAccount, setAccountsLoader } = consentSlice.actions;
export default consentSlice.reducer;
