import React from 'react';
import { withTranslation } from 'react-i18next';
import { SettingsConfirmationComponent } from '../../components';
import { useNavigate } from 'react-router-dom';

const SettingsRepresentativeEnable = ({ t }) => {
	const navigate = useNavigate();
	const goBack = () => navigate('/settings/nominated-representative');

	return (
		<SettingsConfirmationComponent
			goBack={goBack}
			confirmationHeaderLabel={t('label.what_you_should_know_when_you_enable_nominated')}
			headerLabel={t('label.enabling_data_sharing_nominated')}
			contentLabel={t('label.you_may_want_to_check_nominated_enable')}
			footerLabel={t('label.this_permission_change')}
			onClick={goBack}
		/>
	);
};

export default withTranslation()(SettingsRepresentativeEnable);
