import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

const SwitchComponent = ({ t, loading, checked, onDisableClick, onEnableClick }) => {
	const [isChecked, setIsChecked] = useState(checked);
	const disableClassName = classnames('switch-component__button', !isChecked && 'switch-component__button--active');
	const enableClassName = classnames('switch-component__button', isChecked && 'switch-component__button--active');

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	return (
		<div className="switch-component__switch-wrapper">
			{loading ? (
				<span className="icon-loader-outline rotating"></span>
			) : (
				<>
					<span
						className={disableClassName}
						onClick={() => {
							if (isChecked) {
								setIsChecked(false);
								onDisableClick();
							}
						}}>
						{t('label.disable')}
					</span>
					<span
						className={enableClassName}
						onClick={() => {
							if (!isChecked) {
								setIsChecked(true);
								onEnableClick();
							}
						}}>
						{t('label.enable')}
					</span>
				</>
			)}
		</div>
	);
};

export default withTranslation()(SwitchComponent);
