import React from 'react';
import { withTranslation } from 'react-i18next';
import { HeaderComponent } from '../components';

const ErrorPage = ({ t, icon, title, description }) => (
	<div className="main-page error-page">
		<HeaderComponent />
		<div className="error-page__container">
			<span className={icon}></span>
			<div className="error-page__title">{title}</div>
			<div className="error-page__description">{description}</div>
		</div>
	</div>
);

export default withTranslation()(ErrorPage);
