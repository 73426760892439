import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Button = React.memo((props) => {
	const { theme, spanClassName, text, loading, id, disabled, ...rest } = props;
	const buttonClassName = classnames('btn-container', `btn-container__${theme}`);

	return (
		<button className={buttonClassName} id={id} disabled={disabled || loading} {...rest}>
			{loading && <span className="icon-loader-outline rotating"></span>}
			<span className={loading ? 'hidden' : spanClassName}>{text}</span>
		</button>
	);
});

Button.propTypes = {
	spanClassName: PropTypes.string
};

export default Button;
