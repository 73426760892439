import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { HeaderComponent, DescriptionComponent, FooterComponent, CancelPopUpComponent } from '../components';
import { numberRegex, setItem, validateDateOfBirth, useHookGetBrand } from '../helpers';
import { Button, Input } from '../basiqComponents';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectMemberErrorMessage, selectExpiresAt, selectMemberLoading, selectBrandError } from '../store/slices/member/selectors';
import { fetchMemberDetails } from '../store/slices/member/thunks';
import { selectProfile } from '../store/slices/profile/slice';
import { sendError } from '../store/slices/error/thunks';
import { globalConstants } from '../constants';
import { useMask } from '@react-input/mask';
import { removeBrand, setBrand, setBrandErrorMessage } from '../store/slices/member/slice';

const MemberPage = ({ t }) => {
	const defaultAriaLabel = t('label.continue_button_please_enter_a_member_number');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const brand = searchParams?.get('brand');
	const memberLoading = useSelector(selectMemberLoading);
	const memberErrorMessage = useSelector(selectMemberErrorMessage);
	const brandErrorMessage = useSelector(selectBrandError);
	const expiresAt = useSelector(selectExpiresAt);
	const storedBrand = useHookGetBrand();
	const [memberId, setMemberID] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState('');
	const [cancelProcess, setCancelProcess] = useState();
	const [ariaLabel, setAriaLabel] = useState(defaultAriaLabel);
	const [dateOfBirthError, setDateOfBirthError] = useState();
	const inputRef = useRef();
	const dateOfBirthRef = useMask({ mask: 'dd/mm/yyyy', replacement: { d: /\d/, m: /\d/, y: /\d/ }, showMask: true, separate: true });

	useEffect(() => {
		inputRef && inputRef.current && inputRef.current.focus();
	}, [inputRef]);

	useEffect(() => {
		document.title = 'Consumer page';
	}, []);

	useEffect(() => {
		memberErrorMessage && setAriaLabel(defaultAriaLabel);
	}, [memberErrorMessage, defaultAriaLabel]);

	useEffect(() => {
		if (brand) {
			setItem(globalConstants.BRAND, brand);
			dispatch(setBrand(brand));
		} else if (storedBrand) {
			dispatch(removeBrand());
		}
	}, [brand]);

	useEffect(() => {
		return () => dispatch(setBrandErrorMessage(''));
	}, [dispatch]);

	const handleContinueClick = (e) => {
		e.preventDefault();
		if (!brand) {
			dispatch(setBrandErrorMessage('error.to_access_your_ob_dashboard'));
		} else {
			dispatch(fetchMemberDetails({ memberId, dateOfBirth })).then((status) => {
				if (!status?.error) {
					navigate('/otp');
					dispatch(selectProfile());
				}
			});
		}
	};

	const handleMemberIdChange = (e) => {
		try {
			const value = e.target.value.replaceAll(' ', '');
			if (!numberRegex().test(value) || value.length > 20) {
				return;
			}
			setMemberID(value);
			if (value) {
				setAriaLabel(t('label.continue'));
			} else {
				setAriaLabel(defaultAriaLabel);
			}
		} catch (error) {
			sendError('MemberPage - handleMemberIdChange() - ', error);
		}
	};

	const handleDateOfBirthChange = (e) => {
		const { value } = e.target;
		const parts = value.split('/');
		const dd = Number(parts[0]);
		const mm = Number(parts[1]);
		const yyyy = Number(parts[2]);

		if (!isNaN(dd) && !isNaN(mm) && !isNaN(yyyy) && value.length === 10 && !validateDateOfBirth(value)) {
			setDateOfBirthError(true);
		} else if (dateOfBirthError) {
			setDateOfBirthError(false);
		}

		setDateOfBirth(value);
	};

	return (
		<div className="main-page member-page">
			<HeaderComponent />
			<DescriptionComponent
				title={t('label.connect_to_your_bank')}
				content={
					<>
						<div className="description-component__top-title">{t('label.to_share_your_peoples_choise_data')}</div>
						<div className="description-component__bottom-title">{t('label.please_fill_in_your_member_number')}</div>
					</>
				}
			/>
			<div className="content-wrapper">
				<form className="content-wrapper__form" onSubmit={handleContinueClick}>
					<Input
						inputRef={inputRef}
						name="memberId"
						type="text"
						placeholder={t('label.member_number')}
						label={t('label.member_number')}
						value={memberId}
						autoComplete="off"
						onChange={handleMemberIdChange}
						error={memberErrorMessage}
						aria-required="true"
						aria-invalid={memberErrorMessage}
						maxLength="20"
					/>
					<Input
						containerId="date-of-birth"
						name="dateOfBirth"
						type="text"
						placeholder="dd/mm/yyyy"
						label={t('label.date_of_birth')}
						value={dateOfBirth}
						autoComplete="off"
						onChange={handleDateOfBirthChange}
						error={memberErrorMessage || dateOfBirthError}
						aria-required="true"
						aria-invalid={memberErrorMessage || dateOfBirthError}
						maxLength="20"
						inputRef={dateOfBirthRef}
					/>
					{memberErrorMessage && (
						<div id="member-error-incorrect-code" className="member-page__not-found" data-label={t(memberErrorMessage)}>
							{expiresAt ? t(memberErrorMessage, { mins: expiresAt }) : t(memberErrorMessage)}
						</div>
					)}
					{brandErrorMessage && (
						<div id="member-error-incorrect-code" className="member-page__not-found" data-label={t(brandErrorMessage)}>
							{t(brandErrorMessage)}
						</div>
					)}
					<div className="content-wrapper__bottom-text-wrapper">
						<span className="icon-question-mark-circle"></span>
						<div className="content-wrapper__bottom-text-flex">
							<div className="content-wrapper__bottom-text content-wrapper__bottom-text-flex">
								<span className="content-wrapper__member-number">{t('label.forgot_your_member_number')}</span>
								<span>{t('label.find_it_on_your_debit')}</span>
								<span>{t('label.heritage_bank_give_us_a_call')}</span>
								<span className="content-wrapper__by-clicking">
									{t('label.by_clicking_continue')}{' '}
									<a
										href="https://media.peopleschoice.com.au/-/media/project/peopleschoice/mainsite/files/brochures/brc-86101-a4-platform-terms-of-use.pdf"
										target="_blank"
										rel="noopener noreferrer">
										{t('label.terms_and_conditions')}
									</a>
								</span>
							</div>
						</div>
					</div>
					<div className="content-wrapper__bottom">
						<Button
							id="member-continue-button"
							type="submit"
							text={t('label.continue')}
							theme="secondary-dark"
							spanClassName="primary-text"
							loading={memberLoading}
							onClick={handleContinueClick}
							aria-label={ariaLabel}
							disabled={!memberId || !validateDateOfBirth(dateOfBirth) || brandErrorMessage}
						/>
					</div>
				</form>
			</div>
			<FooterComponent />
			<CancelPopUpComponent visible={cancelProcess} goBack={() => setCancelProcess(false)} />
		</div>
	);
};

export default withTranslation()(MemberPage);
