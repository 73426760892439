import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ConfirmationHeaderComponent, HeaderComponent } from '../../components';
import { globalConstants } from '../../constants';
import { changeMenuItemsStatusNumber } from '../../store/slices/consent/slice';
import { selectNotifications, selectUnreadCount } from '../../store/slices/notification/selectors';
import { restartGlobalPermissionForAnAccount } from '../../store/slices/account/slice';
import { selectGlobalPermissionForAnAccountChanged } from '../../store/slices/account/selectors';

const NotificationDetailsPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notifications = useSelector(selectNotifications);
	const unreadCount = useSelector(selectUnreadCount);
	const globalPermissionForAnAccountChanged = useSelector(selectGlobalPermissionForAnAccountChanged);
	const notificationId = useParams().id;
	let notification = notifications && notifications.find((notification) => notification.notificationId === notificationId);
	const goBack = () => navigate('/notifications/');

	useEffect(() => {
		globalPermissionForAnAccountChanged && dispatch(restartGlobalPermissionForAnAccount());
	}, [dispatch, globalPermissionForAnAccountChanged]);

	useEffect(() => {
		dispatch(changeMenuItemsStatusNumber({ tab: globalConstants.NOTIFICATIONS, statusNumber: unreadCount }));
	}, [dispatch, unreadCount]);

	return (
		<>
			<HeaderComponent onBack={goBack} />
			<div className="notifications-page__details">
				<div className="notifications-page__body-wrapper">
					<ConfirmationHeaderComponent label={t('label.request_declined')} />
					<div className="notifications-page__notification-information">
						<div className="notifications-page__account-name">{notification.accountName}:</div>
						<div>{t('label.the_request_to_change_the_data_sharing_permission')}</div>
						<div>{t('label.a_confirmation_sms')}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(NotificationDetailsPage);
