import { createSlice, current } from '@reduxjs/toolkit';
import { globalConstants } from '../../../constants';
import {
	fetchRepresentatives,
	fetchNotificationsSettings,
	setNotificationsSettings,
	fetchSecondaryUsers,
	changeSecondaryUserAccountStatus,
	representativesAssignNomination
} from './thunks';

export const initialState = {
	fetchRepresentativesStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	fetchNotificationsSettingsStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	setNotificationsSettingsStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	fetchSecondaryUsersStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	changeSecondaryUserAccountStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	representativesAssignNominationStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	setNotificationsSettingsLoading: false,
	secondaryUsers: [],
	representatives: [],
	notificationsSettingsChanged: false
};

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		resetNotificationsSettings: (state) => {
			state.notificationsSettingsChanged = false;
			state.fetchNotificationsSettingsStatus = 'loading';
		},
		showUsersAccounts: (state, action) => {
			state.secondaryUsers = state.secondaryUsers.map((user) => {
				if (user.memberId === action.payload.memberId) {
					user.open = action.payload.open;
				}
				return user;
			});
		},
		showRepresentativesUsersAccount: (state, action) => {
			const { memberId, memberType, open } = action.payload;
			state.representatives = state.representatives.map((user) => {
				if (user.memberId === memberId && user.type === memberType) {
					user.open = open;
				}
				return user;
			});
		}
	},
	extraReducers(builder) {
		builder
			// fetchRepresentatives - START
			.addCase(fetchRepresentatives.pending, (state) => {
				state.fetchRepresentativesStatus = 'loading';
				state.representatives = [];
			})
			.addCase(fetchRepresentatives.fulfilled, (state, action) => {
				state.fetchRepresentativesStatus = 'succeeded';
				state.representatives = action.payload.representatives;
				state.accountsWithNoRepresentatives = action.payload.accountsWithNoRepresentatives;
			})
			.addCase(fetchRepresentatives.rejected, (state, action) => {
				state.fetchRepresentativesStatus = 'failed';
				state.error = action.payload.errorMessage;
			})
			// fetchRepresentatives - END
			// fetchNotificationsSettings - START
			.addCase(fetchNotificationsSettings.pending, (state) => {
				state.fetchNotificationsSettingsStatus = 'loading';
			})
			.addCase(fetchNotificationsSettings.fulfilled, (state, action) => {
				state.fetchNotificationsSettingsStatus = 'succeeded';
				state.sendNotifications = action.payload.sendNotifications;
				state.frequency = action.payload.frequency;
			})
			.addCase(fetchNotificationsSettings.rejected, (state) => {
				state.fetchNotificationsSettingsStatus = 'failed';
			})
			// fetchNotificationsSettings - END
			// setNotificationsSettings - START
			.addCase(setNotificationsSettings.pending, (state) => {
				state.setNotificationsSettingsStatus = 'loading';
				state.notificationsSettingsChanged = false;
			})
			.addCase(setNotificationsSettings.fulfilled, (state) => {
				state.setNotificationsSettingsStatus = 'succeeded';
				state.notificationsSettingsChanged = true;
			})
			.addCase(setNotificationsSettings.rejected, (state) => {
				state.setNotificationsSettingsStatus = 'failed';
			})
			// setNotificationsSettings - END
			// fetchSecondaryUsers - START
			.addCase(fetchSecondaryUsers.pending, (state) => {
				state.fetchSecondaryUsersStatus = 'loading';
			})
			.addCase(fetchSecondaryUsers.fulfilled, (state, action) => {
				state.fetchSecondaryUsersStatus = 'succeeded';
				state.secondaryUsers = action.payload || [];
			})
			.addCase(fetchSecondaryUsers.rejected, (state) => {
				state.fetchSecondaryUsersStatus = 'failed';
			})
			// fetchSecondaryUsers - END
			// changeSecondaryUserAccountStatus - START
			.addCase(changeSecondaryUserAccountStatus.pending, (state, action) => {
				const { secondaryUserMemberId, accountId } = action.meta.arg;
				state.changeSecondaryUserAccountStatus = 'loading';
				state.secondaryUsers = state.secondaryUsers.map((user) => {
					if (user.memberId === secondaryUserMemberId) {
						user.accounts.map((account) => {
							if (account.accountId === accountId) {
								account.loading = true;
							}
							return account;
						});
					}
					return user;
				});
			})
			.addCase(changeSecondaryUserAccountStatus.fulfilled, (state, action) => {
				const { status, secondaryUserMemberId, accountId } = action.meta.arg;
				const { secondaryUserInstructionId } = action.payload;
				state.changeSecondaryUserAccountStatus = 'succeeded';
				state.secondaryUsers = state.secondaryUsers.map((user) => {
					if (user.memberId === secondaryUserMemberId) {
						user.accounts.forEach((account) => {
							if (account.accountId === accountId) {
								account.loading = false;
								account.enabled = status === globalConstants.ENABLE;
								if (secondaryUserInstructionId) {
									account.secondaryUserInstructionId = secondaryUserInstructionId;
								} else {
									delete account.secondaryUserInstructionId;
								}
							}
						});
					}
					return user;
				});
			})
			.addCase(changeSecondaryUserAccountStatus.rejected, (state) => {
				state.changeSecondaryUserAccountStatus = 'failed';
			})
			// changeSecondaryUserAccountStatus - END
			// representativesAssignNomination - START
			.addCase(representativesAssignNomination.pending, (state, action) => {
				const { userMemberId, accountId } = action.meta.arg;
				state.representativesAssignNominationStatus = 'loading';
				state.representatives = state.representatives.map((user) => {
					if (user.memberId === userMemberId) {
						user.accounts.map((account) => {
							if (account.accountId === accountId) {
								account.loading = true;
							}
							return account;
						});
					}
					return user;
				});
			})
			.addCase(representativesAssignNomination.fulfilled, (state, action) => {
				const { userMemberId, accountId, status } = action.meta.arg;
				const { businessNominationId } = action.payload;
				state.representativesAssignNominationStatus = 'succeeded';
				// when changing the status of the representatives user account, we have to check if that account is
				// enabled/disabled for all other users and if it is, it needs to be added/removed to/from accountsWithNoRepresentatives
				let currentAccount = {};
				let allAccountsWithSameId = [];
				const updatedRepresentatives = state.representatives.map((user) => {
					if (user.memberId === userMemberId) {
						user.accounts = user.accounts.map((account) => {
							if (account.accountId === accountId) {
								const updatedAccount = {
									...account,
									loading: false,
									enabled: status === globalConstants.ENABLE
								};
								currentAccount = updatedAccount;
								if (businessNominationId) {
									updatedAccount.businessNominationId = businessNominationId;
								} else {
									delete updatedAccount.businessNominationId;
								}
								return updatedAccount;
							}
							return account;
						});
					}
					allAccountsWithSameId = allAccountsWithSameId.concat(user.accounts.filter((account) => account.accountId === accountId));
					return user;
				});
				const accountsWithNoRepresentatives =
					allAccountsWithSameId.length === allAccountsWithSameId.filter((account) => !account.enabled).length
						? [...state.accountsWithNoRepresentatives, currentAccount]
						: state.accountsWithNoRepresentatives.filter((account) => account.accountId !== currentAccount.accountId);
				state.representatives = updatedRepresentatives;
				state.accountsWithNoRepresentatives = accountsWithNoRepresentatives;
			})
			.addCase(representativesAssignNomination.rejected, (state) => {
				state.representativesAssignNominationStatus = 'failed';
			});
		// representativesAssignNominationStatus - END
	}
});

export const { resetNotificationsSettings, showUsersAccounts, showRepresentativesUsersAccount } = settingsSlice.actions;
export default settingsSlice.reducer;
